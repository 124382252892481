import React, { Component } from "react";
import FooterItem from "../FooterItem";
import Continue2 from "./Continue2";

class FooterBox extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return <FooterItem key={line_item.id.toString()} line_item={line_item} />;
    });

    console.log(Number(this.props.checkout.totalPrice?.amount) + this.props.precioCaja + this.props.precioTar)
    console.log(this.props.checkout.totalPrice)
    console.log(this.props.precioCaja)
    console.log(this.props.precioTar)


    return (
      <footer>
        <div className="footer-wrapper">
          <div className="container-fluid">
            <div className="row p-0 m-0 align-items-center">
              <ul className="box-images col-sm-9 col-lg-7 m-p-1">
                <li className="box-item">
                  <img
                    className="item-img"
                    src={this.props.imagenCaja}
                    alt=""
                  />
                </li>
                <li className="box-item">
                  <img className="item-img" src={this.props.imagenRel} alt="" />
                </li>
                {this.props.nombreTar != undefined ? (
                  <li className="box-item">
                    <img
                      src={this.props.imagenTar}
                      alt=""
                      className="item-img"
                    />
                  </li>
                ) : null}

                {line_items}
              </ul>
              <div className="col-sm-3 col-lg-2 m-w-50 m-p-1">
                <div className="bottom-button-1">
                  CAJA/{this.props.cajaSeleccionada}
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 m-w-50 m-p-1">
                {this.props.cajaFormada.volume == 0 ? (
                  <div
                    disabled
                    style={{
                      background: "grey",
                      border: "grey",
                      cursor: "default",
                    }}
                    className="bottom-button-2"
                  >
                    CONTINUAR / ${" "}
                    {Number(this.props.checkout.totalPrice.amount) +
                      this.props.precioCaja +
                      this.props.precioTar}
                    .00
                  </div>
                ) : (
                  <Continue2
                    nextStep={this.props.nextStep}
                    checkout={this.props.checkout}
                    step={this.props.step}
                    precioCaja={this.props.precioCaja}
                    variantRel={this.props.variantRel}
                    precioTar={this.props.precioTar}
                    nombreTar={this.props.nombreTar}
                    cajaParaCheckout={this.props.cajaParaCheckout}
                    openModalEnvio={this.props.openModalEnvio}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterBox;
