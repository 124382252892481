import React, { Component } from "react";
// import VariantSelector from "./VariantSelector";

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  componentWillMount() {
    this.props.product.options.forEach((selector) => {
      this.setState({
        selectedOptions: { [selector.name]: selector.values[0].value },
      });
    });
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value,
    });
  }

  render() {
    let variantImage = "https://via.placeholder.com/400";
    if (this.props.product.images[0] != undefined) {
      variantImage =
        this.state.selectedVariantImage || this.props.product.images[0].src;
    }
    let variant = this.state.selectedVariant || this.props.product.variants[0];
    let variantQuantity = this.state.selectedVariantQuantity || 1;



    console.log("variant", variant)
    return (
      < div className="col-sm-6 col-md-4 half-col" >
        <div className="card card-resp">
          <a
            className="image-button"
            data-toggle="modal"
            onClick={() => {
              this.props.openModal(this.props.modalId);
            }}
          >
            {this.props.product.images.length ? (
              <img
                className="card-img-top img-resp"
                src={variantImage}
                alt={`${this.props.product.title} product shot`}
              />
            ) : (
              <img
                className="card-img-top img-resp"
                src={variantImage}
                alt={`${this.props.product.title} product shot`}
              />
            )}
          </a>
          <div className="row row-pad">
            <div className="wrapper-button">
              <div className="col-sm-2 p-0">
                <button
                  className="button less"
                  onClick={() =>
                    this.props.deleteVariantFromCart(
                      variant.id,
                      this.props.product.id
                    )
                  }
                >
                  -
                </button>
              </div>

              {this.props.cajaFormada.volume + variant.volume < 9576 ? (
                <React.Fragment>
                  <div className="col-sm-8 p-0">
                    <button
                      className="button agregar"
                      onClick={() =>
                        this.props.addVariantToCart(
                          variant.id,
                          variantQuantity,
                          this.props.modalId
                        )
                      }
                    >
                      AGREGAR
                    </button>
                  </div>
                  <div className="col-sm-2 p-0">
                    <button
                      className="button plus"
                      onClick={() =>
                        this.props.addVariantToCart(
                          variant.id,
                          variantQuantity,
                          this.props.modalId
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <div className="col-xs-10 col-lg-8 p-0">
                  <button
                    style={{ color: "red" }}
                    className="button agregar no-espacio font-little"
                  >
                    No hay espacio para este producto
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="card-body">
            <h5 className="text-left black">{this.props.product.title}</h5>
            <span className="black"><span>$</span>{variant.price.amount}</span>
            <label >
              <input
                hidden={true}
                min="1"
                type="number"
                defaultValue={variantQuantity}
                onChange={this.handleQuantityChange}
              ></input>
            </label>
          </div>
        </div>
      </div >
    );
  }
}

export default Product;
