import React, { Component } from "react";

class FooterItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id } = this.props.line_item.variant;
    return (
      <React.Fragment>
        {id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==" &&
        id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==" &&
        id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==" &&
        id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==" &&
        id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==" ? (
          <li className="box-item">
            <a href="#" className="item-link">
              {this.props.line_item.variant.image != null ? (
                <img
                  src={this.props.line_item.variant.image.src}
                  alt={`${this.props.line_item.title} product shot`}
                  className="item-img"
                />
              ) : (
                <img
                  src="https://via.placeholder.com/400"
                  alt={`${this.props.line_item.title} product shot`}
                  className="item-img"
                />
              )}
              {id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==" &&
              id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==" &&
              id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==" &&
              id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==" &&
              id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==" ? (
                <div className="quantity-badge ng-binding">
                  {this.props.line_item.quantity}
                </div>
              ) : null}
            </a>
          </li>
        ) : null}
      </React.Fragment>
    );
  }
}

export default FooterItem;
