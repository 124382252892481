import React, { Component } from "react";

class TarSeleccionado extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="text-center">
        <p className="text-center black">{this.props.nombreTar}</p>

        <img
          width="240"
          src={this.props.imagenTar}
          alt="product shot"
          style={{ maxHeight: "165px" }}
        />

        <div className="Line-item__content-row">
          <span className="Line-item__price">$20.00</span>
        </div>
      </div>
    );
  }
}

export default TarSeleccionado;
