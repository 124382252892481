import React, { Component } from "react";
import Tarjeta from "./Tarjeta";

class Tarjetas extends Component {
  render() {
    let tarjetas = this.props.tarjetas.map((product, i) => {
      return (
        <Tarjeta
          addVariantToCart={this.props.addVariantToCart}
          checkout={this.props.checkout}
          key={product.id.toString()}
          product={product}
          modalIsOpen={this.props.modalIsOpen}
          openModal={this.props.openModal}
          modalId={i}
        />
      );
    });

    return <div className="row">{tarjetas}</div>;
  }
}

export default Tarjetas;
