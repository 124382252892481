import React, { Component } from "react";
import Swal from 'sweetalert2'

import Start from "./Start";
import End from "./End";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Footer from "./common/Footer";
import Navbar from "./common/Navbar";



const lineItemCajaCrema =
  "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMxMTI0MTAyOTcxNDkwMD9jaGVja291dD0zOTFkYjViMGRjNGNkODQ4NjM3MjIzZTA3YTdiN2JiOA==";

const lineItemCajaNegra =
  "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMxMTI0MTAzMzMxOTM4MD9jaGVja291dD03OTA3NmRiMDIzOWY3MDNjYjk5MzQyNDBmZTBlMWU5OA==";

const lineItemRelCrema =
  "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMxMTI0MTA1ODIyMzA2MD9jaGVja291dD0wMzM2MTEzYjA2YWFhNjdkMmJiODZhM2RkNTFiMTAzNA==";

const lineItemRelNegro =
  "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMxMTI0MTA2NzM5ODEwMD9jaGVja291dD0zZTYzOGRiMGVkOGZkNWJjZWUxOGI3YTBjZWMyZDYxMQ==";

const lineItemRelKraft =
  "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMxMTI0MTA4ODA0MTk0MD9jaGVja291dD0zZTYzOGRiMGVkOGZkNWJjZWUxOGI3YTBjZWMyZDYxMQ==";

const variantCajaNegraChica =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==";

const variantCajaNegraMed =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzM2NDcwNg==";

const variantCajaNegraGrande =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzM5NzQ3NA==";

const variantCajaCremaChica =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==";

const variantCajaCremaMed =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzAwNDI1OA==";
const variantCajaCremaGrande =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzAzNzAyNg==";

const variantCajaXmasChica =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjAzNDc4NTU1ODYyNg==";

const variantCajaXmasMed =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjAzNDc4NTU5MTM5NA==";
const variantCajaXmasGrande =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjAzNDc4NTYyNDE2Mg==";

const variantRelCrema =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==";

const variantRelNegro =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==";

const variantRelKraft =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==";


export class BuildForm extends Component {

  constructor() {
    super();
    this.state = {
      cajaFormada: {
        height: 0,
        width: 0,
        depth: 0,
        volume: 0,
        products: [],
      },
      checkout: { lineItems: [] },
      products: [],
      tarjetas: [],
      cajas: [],
      shop: {},
      step: 0,
      modalIsOpen: false,
      modalEnvioIsOpen: false,
      modalId: 0,
      relDelete: 0,
      cajaDelete: 0,
      relAdd: 0,
      cajaAdd: 0,
      categoria: undefined,
      cajaSeleccionada: "CHICA",
      cajaParaCheckout: undefined,
      nombreTar: undefined,
      imagenTar: undefined,
      precioTar: 0,
      variantTar: undefined,
      nombreCaja: undefined,
      imagenCaja: undefined,
      nombreRel: undefined,
      imagenRel: undefined,
      variantRel: undefined,
      precioCaja: 215,
      bottom: -100,
      de_nota: "",
      para_nota: "",
    };
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.deleteVariantFromCart = this.deleteVariantFromCart.bind(this);
    this.addCatToCart = this.addCatToCart.bind(this);
    this.addBoxToCart = this.addBoxToCart.bind(this);
    this.addBoxToCart2 = this.addBoxToCart2.bind(this);
    this.addCrinkleToCart = this.addCrinkleToCart.bind(this);
    this.addCrinkleToCart2 = this.addCrinkleToCart2.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.incrementQuantity2 = this.incrementQuantity2.bind(this);
    this.decrementQuantity2 = this.decrementQuantity2.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openCheckout = this.openCheckout.bind(this);
    this.addTarToCart = this.addTarToCart.bind(this);
    this.openCheckoutBtn = this.openCheckoutBtn.bind(this);
    this.openModalEnvio = this.openModalEnvio.bind(this);
    this.closeModalEnvio = this.closeModalEnvio.bind(this);

    this.timeout = null;
  }
  showNotification = () => {
    this.setState(
      {
        bottom: 70,
      },
      () => {
        this.timeout = setTimeout(() => {
          this.setState({
            bottom: -100,
          });
        }, 3000);
      }
    );
  };
  onShow = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.setState({ bottom: -100 }, () => {
        this.timeout = setTimeout(() => {
          this.showNotification();
        }, 500);
      });
    } else {
      this.showNotification();
    }
  };
  openModal(idProd) {
    this.setState({ modalIsOpen: true, modalId: idProd });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  openModalEnvio() {
    this.setState({ modalEnvioIsOpen: true });
  }

  closeModalEnvio() {
    this.setState({ modalEnvioIsOpen: false });
  }
  componentWillMount() {
    this.props.client.checkout.create().then((res) => {
      this.setState({
        checkout: res,
      });
    });

    // Fetch all collections, including their products
    this.props.client.collection.fetchAllWithProducts().then((collections) => {
      // Do something with the collections
      console.log(collections);
    });

    // Fetch a single collection by ID, including its products (CAJA Y RELLENO)
    const collectionId_caja =
      "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTI3NDUxMDQzNA==";

    this.props.client.collection
      .fetchWithProducts(collectionId_caja)
      .then((collection) => {
        this.setState({
          cajas: collection.products,
        });
      });

    this.props.client.shop.fetchInfo().then((res) => {
      this.setState({
        shop: res,
      });
    });



    this.props.client.collection
      .fetchWithProducts("Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTk3MTY0OTYzNA==", { productsFirst: 100 })
      .then((collection) => {
        this.setState({
          tarjetas: collection.products,
        });
      });
  }

  decrementQuantity2(lineItem) {
    console.log(lineItem);
    const updatedQuantity = lineItem.quantity - 1;
    this.updateQuantityInCart(lineItem.id, updatedQuantity);

    var cajaFormada = this.state.cajaFormada;
    var productos = cajaFormada.products;

    productos.map((prod, x) => {
      if (prod.variants[0].id == lineItem.variant.id) {
        console.log("este producto sí está en caja formada");

        var new_quantity = prod.variants[0].quantity - 1;
        if (new_quantity > 0) {
          prod.variants[0].quantity = new_quantity;
          console.log("nueva canitdad: ", new_quantity);
        } else {
          productos.splice(x, 1);
          console.log("eliminar producto de cajaformada");
        }
      }
    });
    console.log(cajaFormada);
    var heights = [];
    var widths = [];
    var depths = [];
    var volumes = [];

    productos.map((prod) => {
      heights.push(prod.variants[0].height);
      widths.push(prod.variants[0].width);
      depths.push(prod.variants[0].depth);
      volumes.push(prod.variants[0].volume * prod.variants[0].quantity);
    });

    console.log(
      "heights: ",
      heights,
      " widths; ",
      widths,
      " depths: ",
      depths,
      " volumes: ",
      volumes,
      " length of volumes:",
      volumes.length
    );

    console.log("max-height: ", Math.max(...heights));
    console.log("max-width: ", Math.max(...widths));
    console.log("max-depth: ", Math.max(...depths));

    if (volumes.length != 0) {
      var sum_vol = volumes.reduce((x, y) => x + y);
    } else {
      var sum_vol = 0;
    }
    console.log("sum of volumes: ", sum_vol);
    cajaFormada.height = Math.max(...heights);
    cajaFormada.width = Math.max(...widths);
    cajaFormada.depth = Math.max(...depths);
    cajaFormada.volume = sum_vol;

    var precioCaja = this.state.precioCaja;
    var cajaSeleccionada = this.state.cajaSeleccionada;
    var cajaParaCheckout = this.state.cajaParaCheckout;
    var nombreCaja = this.state.nombreCaja;

    if (nombreCaja == "Caja Premium Crema") {
      if (
        cajaFormada.height > 20 ||
        cajaFormada.width > 16 ||
        cajaFormada.volume > 2720
      ) {
        if (
          cajaFormada.height > 25 ||
          cajaFormada.width > 20 ||
          cajaFormada.volume > 4500
        ) {
          cajaSeleccionada = "GRANDE";
          precioCaja = 290;
          cajaParaCheckout = variantCajaCremaGrande;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaCremaMed;
        }
      } else {
        cajaSeleccionada = "CHICA";
        // precioCaja = 215;
        precioCaja = 215;
        cajaParaCheckout = variantCajaCremaChica;
      }
    } else if (nombreCaja == "Caja Premium Negra") {
      if (
        cajaFormada.height > 20 ||
        cajaFormada.width > 16 ||
        cajaFormada.volume > 2720
      ) {
        if (
          cajaFormada.height > 25 ||
          cajaFormada.width > 20 ||
          cajaFormada.volume > 4500
        ) {
          cajaSeleccionada = "GRANDE";
          precioCaja = 290;
          cajaParaCheckout = variantCajaNegraGrande;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaNegraMed;
        }
      } else {
        cajaSeleccionada = "CHICA";
        precioCaja = 215;
        cajaParaCheckout = variantCajaNegraChica;
      }
    } else {
      if (
        cajaFormada.height > 20 ||
        cajaFormada.width > 16 ||
        cajaFormada.volume > 2720
      ) {
        if (
          cajaFormada.height > 25 ||
          cajaFormada.width > 20 ||
          cajaFormada.volume > 4500
        ) {
          cajaSeleccionada = "GRANDE";
          precioCaja = 290;
          cajaParaCheckout = variantCajaXmasGrande;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaXmasMed;
        }
      } else {
        cajaSeleccionada = "CHICA";
        precioCaja = 215;
        cajaParaCheckout = variantCajaXmasChica;
      }
    }

    this.setState({
      cajaFormada: cajaFormada,
      cajaSeleccionada: cajaSeleccionada,
      precioCaja: precioCaja,
      cajaParaCheckout: cajaParaCheckout,
    });
    console.log("caja formada: ", cajaFormada);
  }

  deleteVariantFromCart(variantId, productId) {
    //Variant Id del product
    console.log(variantId);

    // Buscar Matching Ids
    this.state.checkout.lineItems.map((lineItem) => {
      if (variantId == lineItem.variant.id) {
        console.log(lineItem.id);
        const updatedQuantity = lineItem.quantity - 1;
        this.updateQuantityInCart(lineItem.id, updatedQuantity);
      }
    });

    var counter = 0;
    var cajaFormada = this.state.cajaFormada;
    var productos = cajaFormada.products;

    if (productos.length > 0) {
      productos.map((prod, x) => {
        if (prod.id == productId) {
          console.log("este producto sí está en caja formada");

          var new_quantity = prod.variants[0].quantity - 1;
          counter = counter + 1;
          if (new_quantity > 0) {
            prod.variants[0].quantity = new_quantity;
            console.log("nueva canitdad: ", new_quantity);
          } else {
            productos.splice(x, 1);
            console.log("eliminar producto de cajaformada");
          }
        }
      });

      console.log(cajaFormada);

      if (counter > 0) {
        var heights = [];
        var widths = [];
        var depths = [];
        var volumes = [];

        productos.map((prod) => {
          heights.push(prod.variants[0].height);
          widths.push(prod.variants[0].width);
          depths.push(prod.variants[0].depth);
          volumes.push(prod.variants[0].volume * prod.variants[0].quantity);
        });

        console.log(
          "heights: ",
          heights,
          " widths; ",
          widths,
          " depths: ",
          depths,
          " volumes: ",
          volumes,
          " length of volumes:",
          volumes.length
        );

        console.log("max-height: ", Math.max(...heights));
        console.log("max-width: ", Math.max(...widths));
        console.log("max-depth: ", Math.max(...depths));

        if (volumes.length != 0) {
          var sum_vol = volumes.reduce((x, y) => x + y);
        } else {
          var sum_vol = 0;
        }
        console.log("sum of volumes: ", sum_vol);
        cajaFormada.height = Math.max(...heights);
        cajaFormada.width = Math.max(...widths);
        cajaFormada.depth = Math.max(...depths);
        cajaFormada.volume = sum_vol;

        var precioCaja = this.state.precioCaja;
        var cajaSeleccionada = this.state.cajaSeleccionada;
        var cajaParaCheckout = this.state.cajaParaCheckout;
        var nombreCaja = this.state.nombreCaja;

        if (nombreCaja == "Caja Premium Crema") {
          if (
            cajaFormada.height > 20 ||
            cajaFormada.width > 16 ||
            cajaFormada.volume > 2720
          ) {
            if (
              cajaFormada.height > 25 ||
              cajaFormada.width > 20 ||
              cajaFormada.volume > 4500
            ) {
              cajaSeleccionada = "GRANDE";
              precioCaja = 290;
              cajaParaCheckout = variantCajaCremaGrande;
            } else {
              cajaSeleccionada = "MEDIANA";
              precioCaja = 240;
              cajaParaCheckout = variantCajaCremaMed;
            }
          } else {
            cajaSeleccionada = "CHICA";
            // precioCaja = 215;
            precioCaja = 215;
            cajaParaCheckout = variantCajaCremaChica;
          }
        } else if (nombreCaja == "Caja Premium Negra") {
          if (
            cajaFormada.height > 20 ||
            cajaFormada.width > 16 ||
            cajaFormada.volume > 2720
          ) {
            if (
              cajaFormada.height > 25 ||
              cajaFormada.width > 20 ||
              cajaFormada.volume > 4500
            ) {
              cajaSeleccionada = "GRANDE";
              precioCaja = 290;
              cajaParaCheckout = variantCajaNegraGrande;
            } else {
              cajaSeleccionada = "MEDIANA";
              precioCaja = 240;
              cajaParaCheckout = variantCajaNegraMed;
            }
          } else {
            cajaSeleccionada = "CHICA";
            precioCaja = 215;
            cajaParaCheckout = variantCajaNegraChica;
          }
        } else {
          if (
            cajaFormada.height > 20 ||
            cajaFormada.width > 16 ||
            cajaFormada.volume > 2720
          ) {
            if (
              cajaFormada.height > 25 ||
              cajaFormada.width > 20 ||
              cajaFormada.volume > 4500
            ) {
              cajaSeleccionada = "GRANDE";
              precioCaja = 290;
              cajaParaCheckout = variantCajaXmasGrande;
            } else {
              cajaSeleccionada = "MEDIANA";
              precioCaja = 240;
              cajaParaCheckout = variantCajaXmasMed;
            }
          } else {
            cajaSeleccionada = "CHICA";
            precioCaja = 215;
            cajaParaCheckout = variantCajaXmasChica;
          }
        }

        this.setState({
          cajaFormada: cajaFormada,
          cajaSeleccionada: cajaSeleccionada,
          precioCaja: precioCaja,
          cajaParaCheckout: cajaParaCheckout,
        });
        console.log("caja formada: ", cajaFormada);
      } else {
        console.log("este producto no está en la caja");
      }
    } else {
      console.log("caja vacía");
    }
  }

  addVariantToCart(variantId, quantity, i, note) {
    console.log(quantity);
    var cajaFormada = this.state.cajaFormada;
    var cajaParaCheckout = this.state.cajaParaCheckout;
    var { height, width, depth, volume } = cajaFormada;

    var product = this.state.products[i];

    if (height < product.variants[0].height) {
      height = product.variants[0].height;
    }
    if (width < product.variants[0].width) {
      width = product.variants[0].width;
    }
    if (depth < product.variants[0].depth) {
      depth = product.variants[0].depth;
    }

    volume = volume + product.variants[0].volume;

    console.log(
      "height: ",
      height,
      " width; ",
      width,
      " depth: ",
      depth,
      " volume: ",
      volume
    );

    cajaFormada.height = height;
    cajaFormada.width = width;
    cajaFormada.depth = depth;
    cajaFormada.volume = volume;

    var counter = 0;
    var productos = cajaFormada.products;

    if (productos.length > 0) {
      productos.map((prod) => {
        if (prod.id == product.id) {
          var new_quantity = prod.variants[0].quantity + 1;
          prod.variants[0].quantity = new_quantity;
          console.log(prod.variants[0].quantity);
          counter = counter + 1;
        }
      });
      if (counter == 0) {
        productos.push(product);
      }
    } else {
      productos.push(product);
    }

    console.log(cajaFormada);

    var precioCaja = this.state.precioCaja;
    var cajaSeleccionada = this.state.cajaSeleccionada;
    var nombreCaja = this.state.nombreCaja;
    console.log(nombreCaja);

    if (nombreCaja == "Caja Premium Crema") {
      cajaParaCheckout = variantCajaCremaChica;
      if (height > 20 || width > 16 || volume > 2720) {
        if (height > 25 || width > 20 || volume > 4500) {
          cajaSeleccionada = "GRANDE";
          cajaParaCheckout = variantCajaCremaGrande;
          precioCaja = 290;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaCremaMed;
        }
      }
    } else if (nombreCaja == "Caja Premium Negra") {
      cajaParaCheckout = variantCajaNegraChica;
      if (height > 20 || width > 16 || volume > 2720) {
        if (height > 25 || width > 20 || volume > 4500) {
          cajaSeleccionada = "GRANDE";
          cajaParaCheckout = variantCajaNegraGrande;
          precioCaja = 290;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaNegraMed;
        }
      }
    } else {
      cajaParaCheckout = variantCajaXmasChica;
      if (height > 20 || width > 16 || volume > 2720) {
        if (height > 25 || width > 20 || volume > 4500) {
          cajaSeleccionada = "GRANDE";
          cajaParaCheckout = variantCajaXmasGrande;
          precioCaja = 290;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaXmasMed;
        }
      }
    }

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;
    console.log(variantId);

    this.onShow();

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        console.log("addVariantToCart", res)
        this.setState({
          cajaFormada: cajaFormada,
          checkout: res,
          modalIsOpen: false,
          cajaSeleccionada: cajaSeleccionada,
          precioCaja: precioCaja,
          cajaParaCheckout: cajaParaCheckout,
        });
        // const input = {
        //   note: note,
        // };

        // return this.props.client.checkout
        //   .updateAttributes(checkoutId, input)
        //   .then((checkout) => {
        //     this.setState({
        //       checkout: checkout,
        //       modalIsOpen: false,
        //     });
        //     console.log(checkout);
        //   });
      });
  }
  incrementQuantity2(line_item, line_item_id) {
    var cajaFormada = this.state.cajaFormada;
    var { height, width, volume } = cajaFormada;
    var sku = line_item.variant.sku;
    console.log(sku);
    // Capture Height
    var regexH = /H(.*?)\-/;
    var strToMatch = sku;
    var matchedH = Number(regexH.exec(strToMatch)[1]);
    console.log("Height: ", matchedH);

    // Capture Width
    var regexW = /W(.*?)\-/;
    var matchedW = Number(regexW.exec(strToMatch)[1]);
    console.log("Width: ", matchedW);

    // Capture Depth
    var regexD = /D(.*?)\//;
    var matchedD = Number(regexD.exec(strToMatch)[1]);
    console.log("Depth: ", matchedD);

    var matchedV = matchedH * matchedW * matchedD;
    console.log("Volume", matchedV);

    volume = volume + matchedV;

    cajaFormada.volume = volume;

    var productos = cajaFormada.products;

    productos.map((prod) => {
      if (prod.variants[0].id == line_item.variant.id) {
        var new_quantity = prod.variants[0].quantity + 1;
        prod.variants[0].quantity = new_quantity;
        console.log(prod.variants[0].quantity);
      }
    });

    console.log(cajaFormada);

    var precioCaja = this.state.precioCaja;
    var cajaSeleccionada = this.state.cajaSeleccionada;
    var nombreCaja = this.state.nombreCaja;
    var cajaParaCheckout = this.state.cajaParaCheckout;
    console.log(nombreCaja);

    if (nombreCaja == "Caja Premium Crema") {
      cajaParaCheckout = variantCajaCremaChica;
      if (height > 20 || width > 16 || volume > 2720) {
        if (height > 25 || width > 20 || volume > 4500) {
          cajaSeleccionada = "GRANDE";
          cajaParaCheckout = variantCajaCremaGrande;
          precioCaja = 290;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaCremaMed;
        }
      }
    } else if (nombreCaja == "Caja Premium Negra") {
      cajaParaCheckout = variantCajaNegraChica;
      if (height > 20 || width > 16 || volume > 2720) {
        if (height > 25 || width > 20 || volume > 4500) {
          cajaSeleccionada = "GRANDE";
          cajaParaCheckout = variantCajaNegraGrande;
          precioCaja = 290;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaNegraMed;
        }
      }
    } else {
      cajaParaCheckout = variantCajaXmasChica;
      if (height > 20 || width > 16 || volume > 2720) {
        if (height > 25 || width > 20 || volume > 4500) {
          cajaSeleccionada = "GRANDE";
          cajaParaCheckout = variantCajaXmasGrande;
          precioCaja = 290;
        } else {
          cajaSeleccionada = "MEDIANA";
          precioCaja = 240;
          cajaParaCheckout = variantCajaXmasMed;
        }
      }
    }

    console.log(cajaSeleccionada);

    const updatedQuantity = line_item.quantity + 1;
    return this.updateQuantityInCart(line_item_id, updatedQuantity).then(
      (res) => {
        this.setState({
          cajaFormada: cajaFormada,
          cajaSeleccionada: cajaSeleccionada,
          precioCaja: precioCaja,
          cajaParaCheckout: cajaParaCheckout,
        });
      }
    );
  }
  addCatToCart(catId) {
    console.log("catid", catId)
    return this.props.client.collection
      .fetchWithProducts(catId, { productsFirst: 220 })
      .then((collection) => {
        var products = [];
        console.log(products);
        console.log(collection);
        collection.products.map((product, i) => {
          // PRODUCT MEASUREMENTS

          // Product Title and SKU
          console.log(product.title);
          console.log(product.variants[0].sku);

          // Capture Height
          var regexH = /H(.*?)\-/;
          var strToMatch = product.variants[0].sku;
          console.log(strToMatch);
          console.log(Number(regexH.exec(strToMatch)));
          var matchedH = Number(regexH.exec(strToMatch)[1]);
          // console.log("Height: ", matchedH);

          // Capture Width
          var regexW = /W(.*?)\-/;
          var strToMatch = product.variants[0].sku;
          var matchedW = Number(regexW.exec(strToMatch)[1]);
          // console.log("Width: ", matchedW);

          // Capture Depth
          var regexD = /D(.*?)\//;
          var strToMatch = product.variants[0].sku;
          var matchedD = Number(regexD.exec(strToMatch)[1]);
          // console.log("Depth: ", matchedD);

          var volume = matchedH * matchedW * matchedD;
          // console.log("Volume", volume);

          product.variants[0].quantity = 1;
          product.variants[0].height = matchedH;
          product.variants[0].width = matchedW;
          product.variants[0].depth = matchedD;
          product.variants[0].volume = volume;

          products.push(product);
        });
        // console.log("State ", this.state.products);
        // console.log("Products ", products);
        this.setState({
          products: collection.products,
          step: 2,
        });
        // console.log("New State ", this.state);
      });
  }
  addBoxToCart(variantId, quantity) {
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;
    const { cajaDelete } = this.state;

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
          modalIsOpen: false,
          cajaAdd: variantId,
        });
        cajaDelete != 0
          ? variantId == variantCajaNegraChica
            ? this.props.client.checkout
              .removeLineItems(checkoutId, cajaDelete)
              .then((res) => {
                this.setState({
                  checkout: res,
                  cajaDelete: lineItemCajaNegra,
                });
              })
            : this.props.client.checkout
              .removeLineItems(checkoutId, cajaDelete)
              .then((res) => {
                this.setState({
                  checkout: res,
                  cajaDelete: lineItemCajaCrema,
                });
              })
          : variantId == variantCajaNegraChica
            ? this.setState({
              cajaDelete: lineItemCajaNegra,
            })
            : this.setState({
              cajaDelete: lineItemCajaCrema,
            });
      });
  }

  addBoxToCart2(nombre, imagen) {
    if (nombre === "Caja Premium Xmas") {
      this.setState({
        nombreCaja: nombre,
        imagenCaja: imagen,
        precioCaja: 215,
      });
      console.log("hola");
    }
    this.setState({
      nombreCaja: nombre,
      imagenCaja: imagen,
    });
  }

  addCrinkleToCart2(nombre2, imagen2, variantRel) {
    this.setState({
      nombreRel: nombre2,
      imagenRel: imagen2,
      variantRel: variantRel,
    });
  }

  /*
  if de_nota is undefined it means that it's not a mandatory field
  if de_nota is a string empty, it means that it's a mandatory field but not entered
  if para_nota is undefined it means that it's not a mandatory field
  if para_nota is a string empty, it means that it's a mandatory field but not entered
  if note id undefined it means that it's not a mandatory field 
  if note is a string empty, it means that it's a mandatory field but not entered
  */
  addTarToCart(nombre, imagen, variant, note, de_nota = "", para_nota = "") {

    const checkoutId = this.state.checkout.id;
    console.log("checkoutId", checkoutId)

    const input = {
      note: note,
    };


    //https://shopify.dev/api/storefront/2022-01/input-objects/checkoutattributesupdatev2input
    if (de_nota !== "" || para_nota !== "") {

      this.setState(prev => ({ ...prev, de_nota: de_nota, para_nota: para_nota }))

      input.customAttributes = [
        {
          "key": "De",
          "value": de_nota
        },
        {
          "key": "Para",
          "value": para_nota
        }
      ]
    }


    // if(note == "" || de_nota == "" || para_nota == ""){
    //   //window.alert("error"); 
    //   Swal.fire('Debes ingresar una fecha de envío');
    //   return; 
    // }



    return this.props.client.checkout
      .updateAttributes(checkoutId, input)
      .then((checkout) => {
        this.setState({
          checkout: checkout,
          modalIsOpen: false,
          nombreTar: nombre,
          imagenTar: imagen,
          variantTar: variant,
          precioTar: 20,
        });
        console.log(variant);
      });
  }

  openCheckout(variantId1, quantity, variantId2) {
    console.log(variantId1);
    var variantId = variantId1;
    var lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    var checkoutId = this.state.checkout.id;

    this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
        });
        variantId = variantId2;
        lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
        this.props.client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((res) => {
            this.setState({
              checkout: res,
            });
            variantId = this.state.variantTar;
            lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
            this.props.client.checkout
              .addLineItems(checkoutId, lineItemsToAdd)
              .then((res) => {
                this.setState({
                  checkout: res,
                });
                //validate here 
                if (this.state.cajaParaCheckout != undefined) {
                  window.open(this.state.checkout.webUrl);
                  console.log("caja para checkout", this.state.cajaParaCheckout)
                }
                else {
                  Swal.fire('Debes seleccionar una caja para continuar');
                }
              });
          });
      });
  }
  openCheckoutBtn() {

    if (this.state.cajaParaCheckout != undefined) {
      window.open(this.state.checkout.webUrl);
      console.log("caja para checkout navbar", this.state.cajaParaCheckout)
    }
    else {
      Swal.fire('Debes seleccionar una caja para continuar');
    }
  }
  addCrinkleToCart(variantId, quantity) {
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;
    const { relDelete } = this.state;
    switch (variantId) {
      case variantRelNegro:
        return this.props.client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((res) => {
            this.setState({
              checkout: res,
              modalIsOpen: false,
              relAdd: variantId,
            });
            relDelete != 0
              ? this.props.client.checkout
                .removeLineItems(checkoutId, relDelete)
                .then((res) => {
                  this.setState({
                    checkout: res,
                    relDelete: lineItemRelNegro,
                  });
                })
              : this.setState({
                relDelete: lineItemRelNegro,
              });
          });
      case variantRelCrema:
        return this.props.client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((res) => {
            this.setState({
              checkout: res,
              modalIsOpen: false,
              relAdd: variantId,
            });
            relDelete != 0
              ? this.props.client.checkout
                .removeLineItems(checkoutId, relDelete)
                .then((res) => {
                  this.setState({
                    checkout: res,
                    relDelete: lineItemRelCrema,
                  });
                })
              : this.setState({
                relDelete: lineItemRelCrema,
              });
          });
      case variantRelKraft:
        return this.props.client.checkout
          .addLineItems(checkoutId, lineItemsToAdd)
          .then((res) => {
            this.setState({
              checkout: res,
              modalIsOpen: false,
              relAdd: variantId,
            });
            relDelete != 0
              ? this.props.client.checkout
                .removeLineItems(checkoutId, relDelete)
                .then((res) => {
                  this.setState({
                    checkout: res,
                    relDelete: lineItemRelKraft,
                  });
                })
              : this.setState({
                relDelete: lineItemRelKraft,
              });
          });
    }
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    return this.props.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  removeLineItemInCart(lineItemId, variantId) {
    console.log(variantId);

    var cajaFormada = this.state.cajaFormada;
    var productos = cajaFormada.products;

    productos.map((prod, x) => {
      if (variantId == prod.variants[0].id) {
        productos.splice(x, 1);
      }
    });

    console.log(cajaFormada);

    var heights = [];
    var widths = [];
    var depths = [];
    var volumes = [];

    productos.map((prod) => {
      heights.push(prod.variants[0].height);
      widths.push(prod.variants[0].width);
      depths.push(prod.variants[0].depth);
      volumes.push(prod.variants[0].volume * prod.variants[0].quantity);
    });

    console.log(
      "heights: ",
      heights,
      " widths; ",
      widths,
      " depths: ",
      depths,
      " volumes: ",
      volumes
    );

    console.log("max-height: ", Math.max(...heights));
    console.log("max-width: ", Math.max(...widths));
    console.log("max-depth: ", Math.max(...depths));

    var sum_vol = 0;

    if (Array.isArray(volumes) && volumes.length != false) {
      var sum_vol = volumes.reduce((x, y) => x + y);
    }

    console.log("sum of volumes: ", sum_vol);

    cajaFormada.height = Math.max(...heights);
    cajaFormada.width = Math.max(...widths);
    cajaFormada.depth = Math.max(...depths);
    cajaFormada.volume = sum_vol;

    var cajaSeleccionada = this.state.cajaSeleccionada;
    var precioCaja = this.state.precioCaja;

    if (
      cajaFormada.height > 20 ||
      cajaFormada.width > 16 ||
      cajaFormada.volume > 2720
    ) {
      if (
        cajaFormada.height > 25 ||
        cajaFormada.width > 20 ||
        cajaFormada.volume > 4500
      ) {
        cajaSeleccionada = "GRANDE";
        precioCaja = 290;
      } else {
        cajaSeleccionada = "MEDIANA";
        precioCaja = 240;
      }
    } else {
      cajaSeleccionada = "CHICA";
      precioCaja = 215;
    }

    const checkoutId = this.state.checkout.id;
    console.log(lineItemId);

    return this.props.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this.setState({
          checkout: res,
          cajaFormada: cajaFormada,
          cajaSeleccionada: cajaSeleccionada,
          precioCaja: precioCaja,
        });
      });
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  setStep1 = () => {
    this.setState({
      step: 1,
    });
  };

  setStep2 = () => {
    this.setState({
      step: 2,
    });
  };

  setStep3 = () => {
    this.setState({
      step: 3,
    });
  };


  componentDidUpdate() {
    if (this.state.step !== 2) {
      //window.scrollTo(0, 0);
    }
  }

  render() {
    const { step } = this.state;
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Navbar />
            <Start nextStep={this.nextStep} />
            <Footer />
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Navbar />
            <Step1
              step={step}
              nextStep={this.nextStep}
              categoria={this.state.categoria}
              addCatToCart={this.addCatToCart}
            />
            <Footer />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Navbar />
            <Step2
              step={step}
              nextStep={this.nextStep}
              setStep1={this.setStep1}
              cajas={this.state.cajas}
              client={this.props.client}
              addVariantToCart={this.addVariantToCart}
              addBoxToCart={this.addBoxToCart}
              addBoxToCart2={this.addBoxToCart2}
              addCrinkleToCart={this.addCrinkleToCart}
              addCrinkleToCart2={this.addCrinkleToCart2}
              checkout={this.state.checkout}
              cajaAdd={this.state.cajaAdd}
              relAdd={this.state.relAdd}
              categoria={this.state.categoria}
              nombreCaja={this.state.nombreCaja}
              imagenCaja={this.state.imagenCaja}
              nombreRel={this.state.nombreRel}
              imagenRel={this.state.imagenRel}
            />
            <Footer />
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <Navbar />
            <Step3
              step={step}
              nextStep={this.nextStep}
              setStep1={this.setStep1}
              setStep2={this.setStep2}
              deleteVariantFromCart={this.deleteVariantFromCart}
              addVariantToCart={this.addVariantToCart}
              checkout={this.state.checkout}
              updateQuantityInCart={this.updateQuantityInCart}
              removeLineItemInCart={this.removeLineItemInCart}
              openModal={this.openModal}
              closeModal={this.closeModal}
              precioTar={this.state.precioTar}
              incrementQuantity2={this.incrementQuantity2}
              decrementQuantity2={this.decrementQuantity2}
              modalId={this.state.modalId}
              cajaFormada={this.state.cajaFormada}
              modalIsOpen={this.state.modalIsOpen}
              products={this.state.products}
              cajaSeleccionada={this.state.cajaSeleccionada}
              nombreTar={this.state.nombreTar}
              imagenTar={this.state.imagenTar}
              precioCaja={this.state.precioCaja}
              nombreCaja={this.state.nombreCaja}
              imagenCaja={this.state.imagenCaja}
              nombreRel={this.state.nombreRel}
              imagenRel={this.state.imagenRel}
              bottom={this.state.bottom}
            />
            <Footer />
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <Navbar />
            <Step4
              step={step}
              nextStep={this.nextStep}
              setStep1={this.setStep1}
              setStep2={this.setStep2}
              setStep3={this.setStep3}
              deleteVariantFromCart={this.deleteVariantFromCart}
              addVariantToCart={this.addVariantToCart}
              checkout={this.state.checkout}
              updateQuantityInCart={this.updateQuantityInCart}
              removeLineItemInCart={this.removeLineItemInCart}
              openModal={this.openModal}
              closeModal={this.closeModal}
              openModalEnvio={this.openModalEnvio}
              closeModalEnvio={this.closeModalEnvio}
              modalId={this.state.modalId}
              precioTar={this.state.precioTar}
              incrementQuantity2={this.incrementQuantity2}
              decrementQuantity2={this.decrementQuantity2}
              openCheckout={this.openCheckout}
              cajaFormada={this.state.cajaFormada}
              modalIsOpen={this.state.modalIsOpen}
              modalEnvioIsOpen={this.state.modalEnvioIsOpen}
              products={this.state.products}
              cajaSeleccionada={this.state.cajaSeleccionada}
              precioCaja={this.state.precioCaja}
              nombreCaja={this.state.nombreCaja}
              nombreTar={this.state.nombreTar}
              imagenTar={this.state.imagenTar}
              imagenCaja={this.state.imagenCaja}
              nombreRel={this.state.nombreRel}
              imagenRel={this.state.imagenRel}
              bottom={this.state.bottom}
              tarjetas={this.state.tarjetas}
              variantRel={this.state.variantRel}
              cajaParaCheckout={this.state.cajaParaCheckout}
              addTarToCart={this.addTarToCart}
              client={this.props.client}
              deNota={this.state.de_nota}
              paraNota={this.state.para_nota}
            />
            <Footer />
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <Navbar />
            <End
              openCheckout={this.openCheckout}
              variantRel={this.state.variantRel}
              cajaParaCheckout={this.state.cajaParaCheckout}
              openCheckoutBtn={this.openCheckoutBtn}
            />
            <Footer />
          </React.Fragment>
        );
    }
  }
}

export default BuildForm;
