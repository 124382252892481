import React, { Component } from "react";
import Products from "./Products";
import Timeline from "./common/Timeline";
import MyBox from "./common/MyBox_bak";
import ModalProducto from "./ModalProducto";
import FooterBox from "./common/FooterBox";

class Step3 extends Component {
  constructor() {
    super();
    this.scrollCounter = 0;
    this.scrollTop();
  }
  scrollTop() {
    if (this.scrollCounter == 0) {
      window.scrollTo(0, 0);
      this.scrollCounter += 1;
    }
  }
  render() {
    console.log("chekout", this.props.checkout);
    return (
      <div className="paso-3-section">
        <div className="container">
          <Timeline
            step={this.props.step}
            setStep1={this.props.setStep1}
            setStep2={this.props.setStep2}
          />{" "}
          <img
            src="images/Paso-3.png"
            className="img-main-mobile pasos-mobile"
            alt=""
          />
          <p className="paso-mobile-titulo mb-50">
            Selecciona los productos deseados
          </p>
          <MyBox
            checkout={this.props.checkout}
            updateQuantityInCart={this.props.updateQuantityInCart}
            removeLineItemInCart={this.props.removeLineItemInCart}
            cajaSeleccionada={this.props.cajaSeleccionada}
            precioCaja={this.props.precioCaja}
            nombreCaja={this.props.nombreCaja}
            imagenCaja={this.props.imagenCaja}
            nombreRel={this.props.nombreRel}
            imagenRel={this.props.imagenRel}
            nombreTar={this.props.nombreTar}
            imagenTar={this.props.imagenTar}
            precioTar={this.props.precioTar}
            nextStep={this.props.nextStep}
            step={this.props.step}
            incrementQuantity2={this.props.incrementQuantity2}
            decrementQuantity2={this.props.decrementQuantity2}
            cajaFormada={this.props.cajaFormada}
          />
          <div className="page-width m-pl-0 m-pr-0 margin-top-40">
            <Products
              products={this.props.products}
              addVariantToCart={this.props.addVariantToCart}
              deleteVariantFromCart={this.props.deleteVariantFromCart}
              modalIsOpen={this.props.modalIsOpen}
              modalId={this.props.modalId}
              openModal={this.props.openModal}
              checkout={this.props.checkout}
              cajaFormada={this.props.cajaFormada}
            />
          </div>
        </div>
        <ModalProducto
          modalIsOpen={this.props.modalIsOpen}
          closeModal={this.props.closeModal}
          modalId={this.props.modalId}
          products={this.props.products}
          addVariantToCart={this.props.addVariantToCart}
          step={this.props.step}
        />
        <FooterBox
          checkout={this.props.checkout}
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          cajaSeleccionada={this.props.cajaSeleccionada}
          precioCaja={this.props.precioCaja}
          nextStep={this.props.nextStep}
          step={this.props.step}
          imagenCaja={this.props.imagenCaja}
          imagenRel={this.props.imagenRel}
          precioTar={this.props.precioTar}
          cajaFormada={this.props.cajaFormada}
        />
        <div
          className="notification"
          style={{ bottom: `${this.props.bottom}px` }}
        >
          Producto agregado con éxito
        </div>
      </div>
    );
  }
}
export default Step3;
