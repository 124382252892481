import React, { Component } from "react";
import classnames from "classnames";
import Continue from "./common/Continue";
import Timeline from "./common/Timeline";

export default class Step2 extends Component {
  render() {
    const { nombreCaja, relAdd, nombreRel } = this.props;
    const variantRelCrema =
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==";

    const variantRelNegro =
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==";

    const variantRelKraft =
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==";

    return (
      <div className="paso-2-section">
        <div className="container">
          <Timeline step={this.props.step} setStep1={this.props.setStep1} />
          <img
            src="images/Paso-2.png"
            className="img-main-mobile pasos-mobile"
            alt=""
          />
          <p className="paso-mobile-titulo mb-50">
            Decide el color de tu caja y el relleno
          </p>
          <div className="row text-center">
            <p className="paso-2-texto-cuadrado">COLOR DE CAJA</p>
            <div className="col-md-6">
              <a
                onClick={() =>
                  this.props.addBoxToCart2(
                    "Caja Premium Crema",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/box_nude.png?v=1573505339"
                  )
                }
              >
                <img
                  className={classnames("pointer boxUnselected", {
                    boxSelected: nombreCaja == "Caja Premium Crema",
                  })}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/products/box_nude.png?v=1573505339"
                  alt=""
                />
                <img
                  className={classnames("d-none", {
                    check1: nombreCaja == "Caja Premium Crema",
                  })}
                  src="images/Check_icon.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addBoxToCart2(
                    "Caja Premium Crema",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/box_nude.png?v=1573505339"
                  )
                }
                className="mb-5 black mt-20 pointer"
              >
                Caja Premium Crema
              </p>
            </div>
            <div className="col-md-6">
              <a
                onClick={() =>
                  this.props.addBoxToCart2(
                    "Caja Premium Negra",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/box_black.png?v=1573505409"
                  )
                }
              >
                <img
                  className={classnames("pointer boxUnselected", {
                    boxSelected: nombreCaja == "Caja Premium Negra",
                  })}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/products/box_black.png?v=1573505409"
                  alt=""
                />
                <img
                  className={classnames("d-none", {
                    check1: nombreCaja == "Caja Premium Negra",
                  })}
                  src="images/Check_icon.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addBoxToCart2(
                    "Caja Premium Negra",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/box_black.png?v=1573505409"
                  )
                }
                className="mb-5 black mt-20 pointer"
              >
                Caja Premium Negra
              </p>
            </div>
            {/* <div className="col-md-4">
              <a
                onClick={() =>
                  this.props.addBoxToCart2(
                    "Caja Premium Xmas",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/box_xmas_540x.jpg?v=1605626675"
                  )
                }
              >
                <img
                  className={classnames("pointer boxUnselected", {
                    boxSelected: nombreCaja == "Caja Premium Xmas",
                  })}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/products/box_xmas_540x.jpg?v=1605626675"
                  alt=""
                />
                <img
                  className={classnames("d-none", {
                    check1: nombreCaja == "Caja Premium Xmas",
                  })}
                  src="images/Check_icon.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addBoxToCart2(
                    "Caja Premium Xmas",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/box_xmas_540x.jpg?v=1605626675"
                  )
                }
                className="mb-5 black mt-20 pointer"
              >
                Caja Premium Xmas
              </p>
            </div> */}
          </div>
          <p className="text-left black p-2r">
            Este es el relleno que usamos para proteger los productos dentro de
            tu caja.
          </p>
          <div className="row text-center">
            <p className="paso-2-texto-cuadrado">COLOR DE RELLENO</p>
            <div className="col-md-4">
              <a
                onClick={() =>
                  this.props.addCrinkleToCart2(
                    "Crinkle Crema",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_nude.png?v=1573505569",
                    variantRelCrema
                  )
                }
              >
                <img
                  className={classnames("pointer boxUnselected", {
                    boxSelected: nombreRel == "Crinkle Crema",
                  })}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_nude.png?v=1573505569"
                  alt=""
                />
                <img
                  className={classnames("d-none", {
                    check1: nombreRel == "Crinkle Crema",
                  })}
                  src="images/Check_icon.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCrinkleToCart2(
                    "Crinkle Crema",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_nude.png?v=1573505569",
                    variantRelCrema
                  )
                }
                className="mb-5 black mt-20 pointer"
              >
                Crinkle Crema
              </p>
            </div>
            <div className="col-md-4">
              <a
                onClick={() =>
                  this.props.addCrinkleToCart2(
                    "Crinkle Negro",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_black_1024x1024@2x.png?v=1573505610",
                    variantRelNegro
                  )
                }
              >
                <img
                  className={classnames("pointer boxUnselected", {
                    boxSelected: nombreRel == "Crinkle Negro",
                  })}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_black_1024x1024@2x.png?v=1573505610"
                  alt=""
                />
                <img
                  className={classnames("d-none", {
                    check1: nombreRel == "Crinkle Negro",
                  })}
                  src="images/Check_icon.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCrinkleToCart2(
                    "Crinkle Negro",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_black_1024x1024@2x.png?v=1573505610",
                    variantRelNegro
                  )
                }
                className="mb-5 black mt-20 pointer"
              >
                Crinkle Negro
              </p>
            </div>
            <div className="col-md-4">
              <a
                onClick={() =>
                  this.props.addCrinkleToCart2(
                    "Crinkle Kraft",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_kraft.png?v=1573505639",
                    variantRelKraft
                  )
                }
              >
                <img
                  className={classnames("pointer boxUnselected", {
                    boxSelected: nombreRel == "Crinkle Kraft",
                  })}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_kraft.png?v=1573505639"
                  alt=""
                />
                <img
                  className={classnames("d-none", {
                    check1: nombreRel == "Crinkle Kraft",
                  })}
                  src="images/Check_icon.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCrinkleToCart2(
                    "Crinkle Kraft",
                    "https://cdn.shopify.com/s/files/1/2344/0125/products/crinkle_kraft.png?v=1573505639",
                    variantRelKraft
                  )
                }
                className="mb-5 black mt-20 pointer"
              >
                Crinkle Kraft
              </p>
            </div>
          </div>

          <div className="page-width m-pl-0 m-pr-0 margin-top-40">
            <div className="text-center sticky-button">
              {this.props.nombreCaja == undefined ||
                this.props.nombreRel == undefined ? (
                <button
                  className="button-empezar"
                  disabled
                  style={{ background: "grey" }}
                >
                  CONTINUAR
                </button>
              ) : (
                <Continue nextStep={this.props.nextStep} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
