import React, { Component } from "react";
import Product from "./Product";

class Products extends Component {
  render() {
    let products = this.props.products.map((product, i) => {
      return (
        <Product
          addVariantToCart={this.props.addVariantToCart}
          deleteVariantFromCart={this.props.deleteVariantFromCart}
          checkout={this.props.checkout}
          key={product.id.toString()}
          product={product}
          cajaFormada={this.props.cajaFormada}
          modalIsOpen={this.props.modalIsOpen}
          openModal={this.props.openModal}
          modalId={i}
        />
      );
    });

    return <div className="row">{products}</div>;
  }
}

export default Products;
