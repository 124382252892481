import React, { Component } from "react";
import VariantSelector from "./VariantSelector";

class Tarjeta extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  componentWillMount() {
    this.props.product.options.forEach(selector => {
      this.setState({
        selectedOptions: { [selector.name]: selector.values[0].value }
      });
    });
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function(image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target;
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.product.variants.find(variant => {
      return variant.selectedOptions.every(selectedOption => {
        return (
          selectedOptions[selectedOption.name] ===
          selectedOption.value.valueOf()
        );
      });
    });

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image.src
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

  render() {
    let variantImage = "https://via.placeholder.com/400";
    if (this.props.product.images[0] != undefined) {
      variantImage =
        this.state.selectedVariantImage || this.props.product.images[0].src;
    }
    let variant = this.state.selectedVariant || this.props.product.variants[0];
    let variantQuantity = this.state.selectedVariantQuantity || 1;
    let variantSelectors = this.props.product.options.map(option => {
      return (
        <VariantSelector
          className="dropdown-dark"
          handleOptionChange={this.handleOptionChange}
          key={option.id.toString()}
          option={option}
        />
      );
    });
    return (
      <div className="col-sm-6 col-md-4 half-col">
        <div className="card card-resp">
          <a
            className="image-button"
            data-toggle="modal"
            onClick={() => {
              this.props.openModal(this.props.modalId);
            }}
          >
            {this.props.product.images.length ? (
              <img
                height="232px"
                className="card-img-top img-resp"
                src={variantImage}
                alt={`${this.props.product.title} product shot`}
              />
            ) : (
              <img
                height="232px"
                className="card-img-top img-resp"
                src={variantImage}
                alt={`${this.props.product.title} product shot`}
              />
            )}
          </a>
          <div className="card-body">
            <h5 className="text-center black">{this.props.product.title}</h5>

            <label className="">
              <input
                hidden={true}
                min="1"
                type="number"
                defaultValue={variantQuantity}
                onChange={this.handleQuantityChange}
              ></input>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default Tarjeta;
