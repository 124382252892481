import React, { Component } from "react";

class CajaSeleccionada extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const { id } = this.props.line_item.variant;
    return (
      <div className="text-center">
        <p className="text-center black">{this.props.nombreCaja}</p>

        <img
          width="240"
          src={this.props.imagenCaja}
          alt="product shot"
          style={{ maxHeight: "165px" }}
        />

        <div className="Line-item__content-row">
          <div
            style={{ border: "none", fontWeight: "bold", fontSize: "15px" }}
            className="Line-item__quantity-container"
          >
            CAJA/{this.props.cajaSeleccionada}
          </div>

          <span className="Line-item__price">${this.props.precioCaja}.00</span>
        </div>
      </div>
    );
  }
}

export default CajaSeleccionada;
