import React, { Component } from "react";

class Continue2 extends Component {
  constructor(props) {
    super(props);
    this.openCheckout = this.openCheckout.bind(this);
  }
  continue = (e) => {
    e.preventDefault();
    const { step, nombreTar } = this.props

    if (step === 3) {
      this.props.nextStep();
    }

    if (step === 4 && nombreTar) {
      this.props.openModalEnvio()
    }
  };
  openCheckout() {
    window.open(this.props.checkout.webUrl);
  }
  render() {
    console.log("continue2", this.props);
    return (
      <React.Fragment>
        {this.props.step == 3 ? (
          <div onClick={this.continue} className="bottom-button-2">
            CONTINUAR / ${" "}
            {Number(this.props.checkout.totalPrice.amount) +
              this.props.precioCaja +
              this.props.precioTar}
            .00
          </div>
        ) : (
          <React.Fragment>
            {this.props.nombreTar != undefined ? (
              <div onClick={this.continue} className="bottom-button-2">
                FINALIZAR / ${" "}
                {Number(this.props.checkout.totalPrice.amount) +
                  this.props.precioCaja +
                  this.props.precioTar}
                .00
              </div>
            ) : (
              <div
                disabled
                style={{
                  background: "grey",
                  border: "grey",
                  cursor: "default",
                }}
                className="bottom-button-2"
              >
                FINALIZAR / ${" "}
                {Number(this.props.checkout.totalPrice.amount) +
                  this.props.precioCaja +
                  this.props.precioTar}
                .00
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Continue2;
