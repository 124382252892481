import React, { Component } from "react";

export class Continue extends Component {
  constructor(props) {
    super(props);
  }
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  testFunc = (e) => {
    e.preventDefault();
    console.log("click");
  };
  render() {
    return (
      <React.Fragment>
        {this.props.step == 4 ? (
          <React.Fragment>
            {this.props.nombreTar != undefined ? (
              <>
                <button
                  onClick={() => {
                    this.props.openModalEnvio();
                  }}
                  className="button-empezar"
                >
                  FINALIZAR
                </button>
              </>
            ) : (
              <button
                disabled
                style={{
                  background: "grey",
                  border: "grey",
                  cursor: "default",
                }}
                className="button-empezar"
              >
                FINALIZAR
              </button>
            )}
          </React.Fragment>
        ) : (
          <button onClick={this.continue} className="button-empezar">
            CONTINUAR
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default Continue;
