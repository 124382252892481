import React, { Component } from "react";

class RelSeleccionado extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const { id } = this.props.line_item.variant;
    return (
      <div className="text-center">
        <p className="text-center black">{this.props.nombreRel}</p>

        <img
          width="240"
          src={this.props.imagenRel}
          alt="product shot"
          style={{ maxHeight: "165px" }}
        />

        <div className="Line-item__content-row">
          <span className="Line-item__price">$0.00</span>
        </div>
      </div>
    );
  }
}

export default RelSeleccionado;
