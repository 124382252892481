import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PropTypes from "prop-types";

class Delayed extends Component {
  constructor(props) {
    super(props);
    this.state = { hidden: true };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hidden: false });
    }, this.props.waitBeforeShow);
  }

  render() {
    return this.state.hidden ? "" : this.props.children;
  }
}

Delayed.propTypes = {
  waitBeforeShow: PropTypes.number.isRequired,
};

class End extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(
      this.props.openCheckout(
        this.props.variantRel,
        1,
        this.props.cajaParaCheckout
      ),
      3000
    );
  }

  render() {
    return (
      <div className="start-section">
        <div className="container">
          <div className="section-header" style={{ marginTop: "300px" }}>
            <Delayed waitBeforeShow={2000}>
              <p style={{ textAlign: "center" }}>¡LISTO!</p>
            </Delayed>
            <h1
              style={{ color: "black", textAlign: "center" }}
              className="m-mt-35"
            >
              PREPARANDO TU CAJA
            </h1>
            <p style={{ textAlign: "center", marginBottom: "40px" }}>
              ¡Por favor espera un momento en lo que preparamos tu caja!
            </p>
            <Delayed waitBeforeShow={2500}>
              <img
                src="images/Check_icon.png"
                style={{ display: "block", margin: "auto" }}
                alt=""
              />
            </Delayed>
            <Loader
              type="TailSpin"
              color="#939248"
              style={{ textAlign: "center" }}
              height={100}
              width={100}
              timeout={1000} //2 secs
            />
          </div>
          <Delayed waitBeforeShow={2000}>
            <div className="page-width m-pr-0 m-pl-0 margin-top-40">
              <div className="text-center">
                <button
                  onClick={this.props.openCheckoutBtn}
                  className="button-empezar-cart"
                >
                  IR AL CARRITO
                </button>{" "}
                <br />
                <button
                  onClick={() => window.location.reload(false)}
                  className="button-empezar-refresh margin-top-40"
                >
                  ARMAR OTRA BOXCHOIS
                </button>
              </div>
            </div>
          </Delayed>
        </div>
      </div>
    );
  }
}

export default End;
