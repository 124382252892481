import React, { Component } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "contents",
  },
  overlay: {
    overflow: "scroll",
    zIndex: 99999,
  },
};
Modal.setAppElement("#root");

class ModalProducto extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  addVariantToCart(variantId, quantity) {
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target;
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.product.variants.find((variant) => {
      return variant.selectedOptions.every((selectedOption) => {
        return (
          selectedOptions[selectedOption.name] ===
          selectedOption.value.valueOf()
        );
      });
    });

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image.src,
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value,
    });
  }

  renderModal = () => {
    if (this.props.modalId !== null) {
      const product = this.props.products[this.props.modalId];
      let variantImage;

      // let variantImage = "https://via.placeholder.com/400";
      if (product.images[0] != undefined) {
        variantImage = this.state.selectedVariantImage || product.images[0].src;
      }

      let variant = this.state.selectedVariant || product.variants[0];
      let variantQuantity = this.state.selectedVariantQuantity || 1;
      return (
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="image-variant">
                  {product.images.length ? (
                    <img
                      // className="card-img-top img-resp"
                      src={variantImage}
                      alt={variantImage}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 text-justify">
                <h1
                  style={{
                    color: "black",
                    fontSize: "1.5rem",
                    marginTop: ".5rem",
                  }}
                >
                  {product.title}
                </h1>
                <p>{product.description}</p>
              </div>
              <div className="col-md-12">
                <button
                  style={{ boxShadow: "none", backgroundColor: "#fff0ce" }}
                  className="button"
                  onClick={() =>
                    this.props.addVariantToCart(
                      variant.id,
                      variantQuantity,
                      this.props.modalId
                    )
                  }
                >
                  AGREGAR
                </button>
                {/* <div className="row mt-1">
                  <div className="col-sm-2 col-md-2 quantity-button-1">
                    <button
                      style={{ width: "100%" }}
                      className="button-quantity"
                    >
                      {" "}
                      -{" "}
                    </button>
                  </div>

                  <div className="quantity-label col-sm-8 col-md-8 text-center">
                    <span> 1 producto en caja</span>
                  </div>
                  <div className="col-sm-2 col-md-2 quantity-button-2 text-right">
                    <button
                      style={{ width: "100%" }}
                      className="button-quantity"
                    >
                      {" "}
                      +{" "}
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Producto Modal"
      >
        <div className="modal-wrapper">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Informacion Adicional
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div>{this.renderModal()}</div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ModalProducto;
