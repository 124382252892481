import React, { Component } from "react";
import Modal from "react-modal";
import Swal from 'sweetalert2'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "contents",
  },
  overlay: {
    overflow: "scroll",
    zIndex: 100,
  },
};
Modal.setAppElement("#root");

class ModalEnvio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      showForm: false,
      showHourMsg: false,
      hourMsg: "",
      inputDate: "",
      valueDate: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

  }

  isValidDate(datePicked) {
    /*
    1.- detectar el día y hora en el momento de dar click en finalize en la ciudad de mexico
    2.- ajustar el día mínimo en que se puede hacer una compra 
    3.- tener la hora de envío deseada para enviar
    4.- si la hora de envío deseada es mas pequeña que la hora de envio permitida, prevenir el evento y  notificar cuál es el día mínimo para realizar la acción 
    */

    const instantDate = new Date(new Date(new Date()).toLocaleString("en-US", { timeZone: "America/Mexico_City" }));
    const dayOfTheWeek = {
      sunday: 0,
      monday: 1,
      thuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };
    if (instantDate.getDay() == dayOfTheWeek.saturday) {
      console.log("todo handle saturdays");
      instantDate.setDate(instantDate.getDate() + 2);
      instantDate.setHours(0, 0, 0);
    }
    else if (instantDate.getDay() == dayOfTheWeek.sunday) {
      console.log("todo handle dunsays");
      instantDate.setDate(instantDate.getDate() + 1);
      instantDate.setHours(0, 0, 0);
    }
    else if ((instantDate.getDay() == dayOfTheWeek.friday) && (instantDate.getHours() >= 12)) {
      console.log("todo handle fridays after 12");
      instantDate.setDate(instantDate.getDate() + 3);
      instantDate.setHours(0, 0, 0);
    }
    else if (instantDate.getHours() >= 12) {
      console.log("todo handle when requests occurs after 12 mon-thursday");
      instantDate.setDate(instantDate.getDate() + 1);
      instantDate.setHours(0, 0, 0);
    }
    else {
      console.log("todo handle when requests occurs before 12 lun-friday");
      instantDate.setHours(0, 0, 0);
    }
    console.log(`instantDate ${instantDate.toLocaleString()}`);
    console.log(`datePicked ${datePicked.toLocaleString()}`);
    if (datePicked.getTime() < instantDate.getTime()) {
      console.log(`datepicked ${datePicked} instantDate: ${instantDate}`);
      console.log(instantDate.toLocaleString('es-MX'));
      const [day, month, year, hours] = instantDate.toLocaleString('es-MX').split('/');
      const mes = {
        1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre'
      }
      throw new Error(`${day} de ${mes[month]} del ${year.split(' ')[0]}`);
    }
    return true;

  }

  finalize = (e) => {
    e.preventDefault();
    // const checkoutId = this.props.checkout.id;
    // console.log("checkoutid", checkoutId)

    try {

      /* iOS validations */
      const date = this.state.inputDate;
      const [year, month, day] = this.state.inputDate.split('-');
      const selectedDateTime = new Date(year, month - 1, day);
      const currentDateTime = new Date();
      console.log("date", date);
      console.log("SelectedDateTime", selectedDateTime);
      console.log("currentDateTime", currentDateTime);


      // if (selectedDateTime < currentDateTime) {
      //   return Swal.fire('Ingresa una fecha valida.');
      // }
      console.log("state inputdate", this.state.inputDate);
      console.log("hiohio", selectedDateTime);

      if ((date != '') && (date != null) && this.isValidDate(selectedDateTime)) {
        const checkoutId = this.props.checkout.id;
        console.log("checkoutid", checkoutId);


        const { deNota, paraNota } = this.props;
        // console.log({ deNota, paraNota })

        const input = {};
        input.customAttributes = [
          {
            "key": "De",
            "value": deNota
          },
          {
            "key": "Para",
            "value": paraNota
          },
          {
            "key": "Fecha entrega",
            "value": date
          },
        ]

        console.log(this.props.client)
        this.props.client.checkout
          .updateAttributes(checkoutId, input)
          .then((checkout) => {
            console.log(checkout)
            this.props.nextStep()
          });

      }
      else {
        Swal.fire('Debes ingresar una fecha de envío');
      }
    }
    catch (error) {
      Swal.fire(`Debes ingresar una fecha de envío después del ${error.message}`);
    }
  };

  handleNoFinalize = (e) => {
    e.preventDefault();
    (this.props.nextStep())
  }

  parseDateToInputDateVal(day, month, year) {
    if (day && month && (year.toString().length == 4)) {
      day = day <= 9 ? '0' + day : day;
      month = month <= 9 ? '0' + month : month;
      return `${year}-${month}-${day}`
    }
    else {
      throw new Error('year must be represented in yyyy format');
    }

  }
  // handleValue(){
  //   return (new Date().toLocaleString("en-US",{ year: 'numeric', month: '2-digit', day: '2-digit' }));

  // }
  handleValue() {
    var dateObj = new Date();
    var month = dateObj.getMonth() + 1; //months from 1-12
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();

    const newdate = this.parseDateToInputDateVal(day, month, year);
    day = day <= 9 ? '0' + day : day;
    month = month <= 9 ? '0' + month : month;
    if (this.state.inputDate == "" || this.state.inputDate == undefined) {
      this.state.inputDate = `${year}-${month}-${day}`; // this format is required 
    }

    console.log(`newDate : ${newdate}`);
    return newdate;
  }

  handleChange(event) {
    this.setState({ note: event.target.value });
  }


  /**
   *  Problem:
   *  iOS open a different HTML5 date tag, where the user can select a wrong date and then go checkout. 
   * 
   */
  handleChangeDate(event) {
    try {

      // const isIpad = window.navigator.userAgent.match(/iPad/i)
      // const isIphone = window.navigator.userAgent.match(/iPhone/i)
      // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      // const dateElement = event.target
      // const yesterday = new Date()
      // yesterday.setDate(yesterday.getDate() - 1);
      const [pickedYear, pickedMonth, pickedDay] = event.target.value.split('-')
      //const selectedDateTime = new Date(event.target.value);
      //const selectedDateTime = new Date(new Date(event.target.value).toLocaleString("en-US"));
      const selectedDateTime = new Date(Number(pickedYear), Number(pickedMonth) - 1, Number(pickedDay), 0, 0, 0, 0);
      // const currentDateTime = new Date(); 
      console.log(`event picker date ${event.target.value}`);

      console.log(`user picker date ${selectedDateTime}`);


      if (this.isValidDate(selectedDateTime)) {
        const dateElement = event.target;
        this.setState(prev => ({ ...prev, showHourMsg: true }));


        let availableDate;
        const currentDateValue = event.target.value
        const currentDate = new Date(new Date(new Date()).toLocaleString("en-US", { timeZone: "America/Mexico_City" }));
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        let day = currentDate.getDate();

        console.log({ currentDateValue })

        const dayOfTheWeek = {
          sunday: 0,
          monday: 1,
          thuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
        };

        if (currentDate.getDay() == dayOfTheWeek.saturday) {
          console.log("todo handle saturdays");
          this.setState(prev => ({ ...prev, hourMsg: "Pedidos en día sábado despues de las 12:00pm, se podran programar el día lunes." }))
        }
        else if (currentDate.getDay() == dayOfTheWeek.sunday) {
          this.setState(prev => ({ ...prev, hourMsg: "Pedidos en día domingo despues de las 12:00pm, se podran programar el día lunes." }))
          console.log("todo handle dunsays");
        }
        else if ((currentDate.getDay() == dayOfTheWeek.friday) && (currentDate.getHours() >= 12)) {
          console.log("todo handle fridays after 12");
          this.setState(prev => ({ ...prev, hourMsg: "Pedidos en día viernes despues de las 12:00pm, se podran programar el día lunes." }))
        }
        else if (currentDate.getHours() >= 12) {
          console.log("todo handle when requests occurs after 12 mon-thursday");
          this.setState(prev => ({ ...prev, hourMsg: "Pedidos despues de las 12:00pm, se podran programar para el día siguiente." }))
        }
        else {
          console.log("todo handle when requests occurs before 12 lun-friday");
          this.setState(prev => ({ ...prev, hourMsg: "Puedes programar tu envío desde hoy" }))
        }

        availableDate = this.parseDateToInputDateVal(day, month, year);
        console.log(`availableDate : ${availableDate}`);
        dateElement.setAttribute("min", availableDate);

        this.setState({ inputDate: event.target.value, note: event.target.value, valueDate: event.target.value });
      }


    }
    catch (error) {
      return Swal.fire(`No puede agendar un pedido antes del ${error.message}, intente con otra fecha`);
    }
  }


  showForm = (e) => {
    e.preventDefault();
    this.setState({ showForm: true });
  }

  renderModal = () => {
    return (
      <div className="modal-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12"></div>
            {this.state.showHourMsg ? <div className="alert alert-warning">{this.state.hourMsg}</div> : null}
            <div className="col-md-12 text-justify">
              <div className="tarjeta-checkbox">
                ¿Deseas programar tu entrega dentro de la ciudad de México?
              </div>
            </div>
            <div className="col-md-6">
              <button
                style={{ boxShadow: "none", backgroundColor: "#fff0ce" }}
                className="button"
                onClick={this.showForm}
              >
                SÍ
              </button>
            </div>
            <div className="col-md-6">
              <button
                style={{
                  boxShadow: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
                className="button"
                onClick={this.handleNoFinalize}
              >
                NO
              </button>
            </div>
            {this.state.showForm ? (
              <form
                style={{
                  width: "100%",
                }}
                action=""
              >
                {/* <div className="col-md-12">
                  <h5>Información de contacto</h5>
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Email"
                    className="input-envio"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="tel"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Número de teléfono"
                    className="input-envio"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Nombre completo"
                    className="input-envio"
                  />
                </div>
                <div className="col-md-12">
                  <h5>Dirección de envío</h5>
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Calle y número de casa"
                    className="input-envio"
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Apartamento, local, etc."
                    className="input-envio"
                  />
                </div> */}
                <div className="col-md-12">
                  <h5>Fecha de entrega</h5>
                </div>
                <div className="col-md-12">
                  <input
                    type="date"
                    value={this.state.valueDate || this.handleValue()}
                    onChange={this.handleChangeDate}
                    placeholder="Fecha de entrega"
                    min={this.handleValue()}
                    className="input-envio"
                  />
                </div>
                <div className="col-md-12">
                  <button
                    style={{ boxShadow: "none", backgroundColor: "#fff0ce" }}
                    className="button"
                    onClick={this.finalize}
                  >
                    PROGRAMAR ENVÍO
                  </button>

                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    console.log(this.props)
    return (
      <Modal
        isOpen={this.props.modalEnvioIsOpen}
        onRequestClose={this.props.closeModalEnvio}
        style={customStyles}
        contentLabel="Producto Modal"
      >
        <div className="modal-wrapper">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Programar envío
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModalEnvio}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div>{this.renderModal()}</div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModalEnvio}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ModalEnvio;
