import React, { Component } from "react";
import Continue from "./common/Continue";

class Start extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    return (
      <div className="start-section">
        <img src="images/main_mobil.jpg" className="img-main-mobile" alt="" />
        <div className="container">
          <div className="section-header">
            <h1
              style={{ color: "black", fontFamily: "Sacramento" }}
              className="m-mt-35"
            >
              #SendLove
              <link
                href="https://fonts.googleapis.com/css?family=Sacramento"
                rel="stylesheet"
              />
            </h1>
            <p
              className="textleft"
              style={{ fontSize: "15px", color: "black" }}
            >
              En esta sección podrás diseñar tu propio regalo desde cero.
              Decidir el color y relleno de la caja. Asi como los productos que
              quieres incluir basado en tu presupuesto. Estamos seguros que
              tendrás el regalo más bonito y único para esa persona tan
              especial. <br />
              <br />
              Deberás seguir los pasos mencionados abajo para completar el
              diseño de tu giftbox. Recuerda que siempre que necesites regresar
              al paso anterior, lo podrás hacer en la barra de pasos que
              aparecerá arriba, la cual señalará el paso en el que te
              encuentras. Dando click en los números podrás regresar o avanzar
              al paso en que te quedaste. <br />
              <br />
              Es importante que sepas que el sistema determinará en automático,
              qué tamaño de caja le viene mejor a los productos que selecciones.
            </p>
          </div>
          <img
            src="images/main_custom.jpg"
            className="img-main-custom margin-top-40"
            alt=""
          />

          <img
            src="images/main_mobil_steps.jpg"
            className="img-main-mobile margin-top-40"
            alt=""
          />

          <div className="page-width m-pr-0 m-pl-0 margin-top-40">
            <div className="text-center sticky-button">
              <button onClick={this.continue} className="button-empezar">
                EMPEZAR GIFTBOX
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Start;
