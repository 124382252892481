import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import LineItem from "../LineItem";
import CajaSeleccionada from "../CajaSeleccionada";
import RelSeleccionado from "../RelSeleccionado";
import classnames from "classnames";
import Continue from "./Continue";
import TarSeleccionado from "../TarSeleccionado";

export class MyBox extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
          cajaFormada={this.props.cajaFormada}
          cajaSeleccionada={this.props.cajaSeleccionada}
          precioCaja={this.props.precioCaja}
          incrementQuantity2={this.props.incrementQuantity2}
          decrementQuantity2={this.props.decrementQuantity2}
        />
      );
    });


    console.log("xd totalprice", this.props.checkout.totalPrice)
    console.log("xd2 caja", this.props.precioCaja)
    console.log("xd tar", this.props.precioTar)


    return (
      <div className="row border align-items-center">
        <div className="col-md-9">
          {this.props.step != 4 ? (
            <React.Fragment>
              {this.props.cajaFormada.products.length < 2 ? (
                <div className="row">
                  <div className="col-md-4">
                    <CajaSeleccionada
                      style={{ display: "inline-block" }}
                      cajaSeleccionada={this.props.cajaSeleccionada}
                      precioCaja={this.props.precioCaja}
                      nombreCaja={this.props.nombreCaja}
                      imagenCaja={this.props.imagenCaja}
                    />
                  </div>
                  <div className="col-md-4">
                    <RelSeleccionado
                      style={{ display: "inline-block" }}
                      nombreRel={this.props.nombreRel}
                      imagenRel={this.props.imagenRel}
                    />
                  </div>
                  <div className="col-md-4">{line_items}</div>
                </div>
              ) : (
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  infinite={true}
                  keyBoardControl={true}
                  deviceType={this.props.deviceType}
                  containerClass="carousel-container-fluid"
                >
                  <CajaSeleccionada
                    cajaSeleccionada={this.props.cajaSeleccionada}
                    precioCaja={this.props.precioCaja}
                    nombreCaja={this.props.nombreCaja}
                    imagenCaja={this.props.imagenCaja}
                  />
                  <RelSeleccionado
                    style={{ display: "inline-block" }}
                    nombreRel={this.props.nombreRel}
                    imagenRel={this.props.imagenRel}
                  />
                  {this.props.nombreTar != undefined ? (
                    <TarSeleccionado
                      nombreTar={this.props.nombreTar}
                      imagenTar={this.props.imagenTar}
                    />
                  ) : null}

                  {line_items}
                </Carousel>
              )}
            </React.Fragment>
          ) : (
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              infinite={true}
              keyBoardControl={true}
              deviceType={this.props.deviceType}
              containerClass="carousel-container-fluid"
            >
              <CajaSeleccionada
                cajaSeleccionada={this.props.cajaSeleccionada}
                precioCaja={this.props.precioCaja}
                nombreCaja={this.props.nombreCaja}
                imagenCaja={this.props.imagenCaja}
              />
              <RelSeleccionado
                style={{ display: "inline-block" }}
                nombreRel={this.props.nombreRel}
                imagenRel={this.props.imagenRel}
              />
              {this.props.nombreTar != undefined ? (
                <TarSeleccionado
                  nombreTar={this.props.nombreTar}
                  imagenTar={this.props.imagenTar}
                />
              ) : null}

              {line_items}
            </Carousel>
          )}
        </div>
        <div className="col-md-3 border-l">
          <div className="caja m-border-t pad-20 border-b">
            <h4 className="text-center">TAMAÑO DE CAJA:</h4>
            <div className="tamaño-caja">
              <div className="boton">
                <button
                  className={classnames({
                    "color--selected": this.props.cajaSeleccionada == "CHICA",
                  })}
                  disabled={true}
                >
                  CHICA
                </button>
              </div>
              <div className="boton">
                <button
                  className={classnames({
                    "color--selected": this.props.cajaSeleccionada == "MEDIANA",
                  })}
                  disabled={true}
                >
                  MEDIANA
                </button>
              </div>
              <div className="boton">
                <button
                  className={classnames({
                    "color--selected": this.props.cajaSeleccionada == "GRANDE",
                  })}
                  disabled={true}
                >
                  GRANDE
                </button>
              </div>
            </div>
          </div>
          <div className="subtotal m-border-b text-center pad-20">
            <h4 className="m-0">SUBTOTAL..</h4>
            <p className="blk">
              ${" "}
              {Number(this.props.checkout.totalPrice.amount) +
                this.props.precioCaja +
                this.props.precioTar}
              .00
            </p>
            {this.props.cajaFormada.volume == 0 ? (
              <button
                className="button-empezar"
                disabled
                style={{ background: "grey" }}
              >
                CONTINUAR
              </button>
            ) : (
              <Continue
                step={this.props.step}
                checkout={this.props.checkout}
                nextStep={this.props.nextStep}
                openCheckout={this.props.openCheckout}
                variantRel={this.props.variantRel}
                openModalEnvio={this.props.openModalEnvio}
                cajaParaCheckout={this.props.cajaParaCheckout}
                nombreTar={this.props.nombreTar}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MyBox;
