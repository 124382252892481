import React, { Component } from "react";
import Timeline from "./common/Timeline";
import MyBox from "./common/MyBox_bak";
import Tarjetas from "./Tarjetas";
import ModalTarjeta from "./ModalTarjeta";
import FooterBox from "./common/FooterBox";
import ModalEnvio from "./ModalEnvio";

export default class Step4 extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      modalId: 0,

    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(idProd) {
    this.setState({ modalIsOpen: true, modalId: idProd });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div className="paso-4-section">
        <div className="container">
          <Timeline
            step={this.props.step}
            setStep1={this.props.setStep1}
            setStep2={this.props.setStep2}
            setStep3={this.props.setStep3}
          />
          {console.log("step3", this.props.setStep3)}
          <img
            src="images/Paso-4.png"
            className="img-main-mobile pasos-mobile"
            alt=""
          />
          <p className="paso-mobile-titulo mb-50">
            Incluye una tarjeta con el motivo del regalo
          </p>
          <MyBox
            checkout={this.props.checkout}
            updateQuantityInCart={this.props.updateQuantityInCart}
            removeLineItemInCart={this.props.removeLineItemInCart}
            cajaSeleccionada={this.props.cajaSeleccionada}
            precioCaja={this.props.precioCaja}
            nombreCaja={this.props.nombreCaja}
            nombreRel={this.props.nombreRel}
            openModalEnvio={this.props.openModalEnvio}
            imagenRel={this.props.imagenRel}
            nombreTar={this.props.nombreTar}
            imagenTar={this.props.imagenTar}
            precioTar={this.props.precioTar}
            openCheckout={this.props.openCheckout}
            variantRel={this.props.variantRel}
            imagenCaja={this.props.imagenCaja}
            nextStep={this.props.nextStep}
            step={this.props.step}
            incrementQuantity2={this.props.incrementQuantity2}
            decrementQuantity2={this.props.decrementQuantity2}
            cajaFormada={this.props.cajaFormada}
            cajaParaCheckout={this.props.cajaParaCheckout}
          />
          <div className="page-width m-pl-0 m-pr-0 margin-top-40">
            {/* <div className="text-center sticky-button">
              <button className="button-empezar">FINALIZAR</button>
            </div> */}
            <Tarjetas
              products={this.props.products}
              addVariantToCart={this.props.addVariantToCart}
              modalIsOpen={this.props.modalIsOpen}
              modalId={this.props.modalId}
              openModal={this.props.openModal}
              tarjetas={this.props.tarjetas}
            />
          </div>
        </div>
        <ModalTarjeta
          modalIsOpen={this.props.modalIsOpen}
          closeModal={this.props.closeModal}
          modalId={this.props.modalId}
          products={this.props.products}
          tarjetas={this.props.tarjetas}
          addVariantToCart={this.props.addVariantToCart}
          step={this.props.step}
          addTarToCart={this.props.addTarToCart}
        />
        <ModalEnvio
          modalEnvioIsOpen={this.props.modalEnvioIsOpen}
          closeModalEnvio={this.props.closeModalEnvio}
          nextStep={this.props.nextStep}
          checkout={this.props.checkout}
          client={this.props.client}
          deNota={this.props.deNota}
          paraNota={this.props.paraNota}
          step={this.props.step}
        />
        <FooterBox
          checkout={this.props.checkout}
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          cajaSeleccionada={this.props.cajaSeleccionada}
          precioCaja={this.props.precioCaja}
          nextStep={this.props.nextStep}
          step={this.props.step}
          variantRel={this.props.variantRel}
          imagenRel={this.props.imagenRel}
          imagenCaja={this.props.imagenCaja}
          cajaFormada={this.props.cajaFormada}
          precioTar={this.props.precioTar}
          nombreTar={this.props.nombreTar}
          imagenTar={this.props.imagenTar}
          cajaParaCheckout={this.props.cajaParaCheckout}
          openModalEnvio={this.props.openModalEnvio}
        />
      </div>
    );
  }
}
