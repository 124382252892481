import React, { Component } from "react";
import classnames from "classnames";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export class Timeline extends Component {
  set_1 = e => {
    e.preventDefault();
    if (this.props.step > 1) {
      confirmAlert({
        message:
          "¿Estás seguro que quieres cambiar el motivo? Se perderá la selección de productos que llevas hasta ahora.",
        buttons: [
          {
            label: "Sí, quiero cambiar motivo.",
            onClick: () => window.location.reload(false)
          },
          {
            label: "Cerrar",
            onClick: () => window.close()
          }
        ]
      });
    }
  };
  set_2 = e => {
    e.preventDefault();
    if (this.props.step > 2) {
      this.props.setStep2();
    }
  };
  set_3 = e => {
    e.preventDefault();
    if (this.props.step > 3) {
      this.props.setStep3();
    }
  };
  render() {
    const { step } = this.props;
    return (
      <div className="timeline resp">
        <a className="op-1" onClick={this.set_1} href="#">
          <div className="steps active" style={{ width: "0%" }}>
            <div className="start paso-1">
              <span>Selecciona un motivo</span>
            </div>
          </div>
        </a>
        <a className="op-1" onClick={this.set_2} href="#">
          <div
            className={classnames("steps inactive", {
              active: step > 1
            })}
            style={{ width: "30%" }}
          >
            <div
              className={classnames("inactive-start paso-2-inactivo", {
                "paso-2": step > 1
              })}
            >
              <span>Escoge color y relleno</span>
            </div>
          </div>
        </a>
        <a className="op-1" onClick={this.set_3} href="#">
          <div
            className={classnames("steps inactive", {
              active: step > 2
            })}
            style={{ width: "30%" }}
          >
            <div
              className={classnames("inactive-start paso-3-inactivo", {
                "paso-3": step > 2
              })}
            >
              <span>Selecciona productos</span>
            </div>
          </div>
        </a>
        <a className="op-1" href="#">
          <div
            className={classnames("steps inactive", {
              active: step > 3
            })}
            style={{ width: "30%" }}
          >
            <div
              className={classnames("inactive-start paso-4-inactivo", {
                "paso-4": step > 3
              })}
            >
              <span>Escoge la tarjeta</span>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default Timeline;
