import React, { Component } from "react";

class Navbar extends Component {
  render() {
    return (
      <div>
        <div id="fsb_container">
          <div id="fsb_background" >
            <div id="fsb_bar" style={{ backgroundColor: "rgb(235, 62, 62)", color: "rgb(255, 246, 129)" }}>
              Estamos presentando fallas en sección "Arma tu Boxchois" si te aparecen productos agotados contáctanos vía chat.
            </div>
          </div>
        </div>
        <nav
          className="navbar navbar-expand-lg navbar-light d-sm-block d-md-none"
          style={{
            borderBottom: "1px solid #e8e9eb",
            margin: "0px",
          }}
        >
          <a
            className="navbar-brand"
            style={{ padding: "0", marginRight: "1.5rem" }}
            href="https://www.boxchois.com/"
          >
            <img
              style={{ width: "165px", display: "block", marginLeft: "22px" }}
              src="https://cdn.shopify.com/s/files/1/2344/0125/files/Logo_Box_Chois_registro-01-01_360x.png?v=1571258941"
              alt=""
            />
          </a>
          <div style={{ height: "100%", display: "contents" }}>
            <a href="https://www.boxchois.com/search">
              <svg
                style={{ width: "20px" }}
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-search"
                viewBox="0 0 37 40"
              >
                <path d="M35.6 36l-9.8-9.8c4.1-5.4 3.6-13.2-1.3-18.1-5.4-5.4-14.2-5.4-19.7 0-5.4 5.4-5.4 14.2 0 19.7 2.6 2.6 6.1 4.1 9.8 4.1 3 0 5.9-1 8.3-2.8l9.8 9.8c.4.4.9.6 1.4.6s1-.2 1.4-.6c.9-.9.9-2.1.1-2.9zm-20.9-8.2c-2.6 0-5.1-1-7-2.9-3.9-3.9-3.9-10.1 0-14C9.6 9 12.2 8 14.7 8s5.1 1 7 2.9c3.9 3.9 3.9 10.1 0 14-1.9 1.9-4.4 2.9-7 2.9z"></path>
              </svg>
            </a>
            <a
              href="https://www.boxchois.com/account/login"
              className="site-header__account"
              style={{ width: "18px" }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-login"
                viewBox="0 0 28.33 37.68"
              >
                <path d="M14.17 14.9a7.45 7.45 0 1 0-7.5-7.45 7.46 7.46 0 0 0 7.5 7.45zm0-10.91a3.45 3.45 0 1 1-3.5 3.46A3.46 3.46 0 0 1 14.17 4zM14.17 16.47A14.18 14.18 0 0 0 0 30.68c0 1.41.66 4 5.11 5.66a27.17 27.17 0 0 0 9.06 1.34c6.54 0 14.17-1.84 14.17-7a14.18 14.18 0 0 0-14.17-14.21zm0 17.21c-6.3 0-10.17-1.77-10.17-3a10.17 10.17 0 1 1 20.33 0c.01 1.23-3.86 3-10.16 3z"></path>
              </svg>
            </a>
            <a href="https://www.boxchois.com/cart">
              <svg
                style={{ width: "20px" }}
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-cart"
                viewBox="0 0 37 40"
              >
                <path d="M36.5 34.8L33.3 8h-5.9C26.7 3.9 23 .8 18.5.8S10.3 3.9 9.6 8H3.7L.5 34.8c-.2 1.5.4 2.4.9 3 .5.5 1.4 1.2 3.1 1.2h28c1.3 0 2.4-.4 3.1-1.3.7-.7 1-1.8.9-2.9zm-18-30c2.2 0 4.1 1.4 4.7 3.2h-9.5c.7-1.9 2.6-3.2 4.8-3.2zM4.5 35l2.8-23h2.2v3c0 1.1.9 2 2 2s2-.9 2-2v-3h10v3c0 1.1.9 2 2 2s2-.9 2-2v-3h2.2l2.8 23h-28z"></path>
              </svg>
            </a>
          </div>
          <button
            className="navbar-toggler"
            style={{ color: "black", border: "none" }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  ARMA TU BOXCHOIS
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  PRE-DISEÑADOS
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >

                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-bebes"
                  >
                    PARA BEBÉS
                  </a>
                  {/* <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-bodas"
                  >
                    PARA BODAS
                  </a> */}
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-ella"
                  >
                    PARA ELLA
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-el"
                  >
                    PARA EL
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/ocasiones-especiales"
                  >
                    OCASIONES ESPECIALES
                  </a>
                  {/* <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/navidad-2020"
                  >
                    NAVIDAD 2020
                  </a> */}
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/collections/ballon-boxes"
                >
                  BALLOON BOXES
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/collections/giftbox-con-flores"
                >
                  BOXCHOIS + FLORES
                </a>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  PRODUCTOS SUELTOS
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/tazas"
                  >
                    TAZAS
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/velas-aromaticas-de-soya"
                  >
                    VELAS
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/wine-totes"
                  >
                    WINE TOTES
                  </a>
                </div>
              </li> */}

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/pages/nosotros"
                >
                  NOSOTROS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/pages/corporativos"
                >
                  REGALOS CORPORATIVOS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/pages/contacto"
                >
                  CONTACTO
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <header className="d-none d-md-block">
          <div className="row">
            <div className="col-md-4">
              <div style={{ height: "100%", display: "flex" }}>
                <a href="https://www.boxchois.com/search"></a>
                <svg
                  style={{ width: "20px" }}
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className="icon icon-search"
                  viewBox="0 0 37 40"
                >
                  <path d="M35.6 36l-9.8-9.8c4.1-5.4 3.6-13.2-1.3-18.1-5.4-5.4-14.2-5.4-19.7 0-5.4 5.4-5.4 14.2 0 19.7 2.6 2.6 6.1 4.1 9.8 4.1 3 0 5.9-1 8.3-2.8l9.8 9.8c.4.4.9.6 1.4.6s1-.2 1.4-.6c.9-.9.9-2.1.1-2.9zm-20.9-8.2c-2.6 0-5.1-1-7-2.9-3.9-3.9-3.9-10.1 0-14C9.6 9 12.2 8 14.7 8s5.1 1 7 2.9c3.9 3.9 3.9 10.1 0 14-1.9 1.9-4.4 2.9-7 2.9z"></path>
                </svg>
              </div>
            </div>
            <div className="col-md-4">
              <a href="https://www.boxchois.com/">
                <img
                  style={{ width: "200px", display: "block", margin: "auto" }}
                  src="https://cdn.shopify.com/s/files/1/2344/0125/files/Logo_Box_Chois_registro-01-01_360x.png?v=1571258941"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-4">
              <div style={{ height: "100%", display: "flex", float: "right" }}>
                <a
                  href="https://www.boxchois.com/account/login"
                  className="site-header__account"
                  style={{ width: "18px" }}
                >
                  <svg
                    style={{ marginTop: "40px" }}
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    className="icon icon-login"
                    viewBox="0 0 28.33 37.68"
                  >
                    <path d="M14.17 14.9a7.45 7.45 0 1 0-7.5-7.45 7.46 7.46 0 0 0 7.5 7.45zm0-10.91a3.45 3.45 0 1 1-3.5 3.46A3.46 3.46 0 0 1 14.17 4zM14.17 16.47A14.18 14.18 0 0 0 0 30.68c0 1.41.66 4 5.11 5.66a27.17 27.17 0 0 0 9.06 1.34c6.54 0 14.17-1.84 14.17-7a14.18 14.18 0 0 0-14.17-14.21zm0 17.21c-6.3 0-10.17-1.77-10.17-3a10.17 10.17 0 1 1 20.33 0c.01 1.23-3.86 3-10.16 3z"></path>
                  </svg>
                </a>
                <a href="https://www.boxchois.com/cart">
                  <svg
                    style={{
                      width: "20px",
                      marginLeft: "15px",
                      marginTop: "40px",
                    }}
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    className="icon icon-cart"
                    viewBox="0 0 37 40"
                  >
                    <path d="M36.5 34.8L33.3 8h-5.9C26.7 3.9 23 .8 18.5.8S10.3 3.9 9.6 8H3.7L.5 34.8c-.2 1.5.4 2.4.9 3 .5.5 1.4 1.2 3.1 1.2h28c1.3 0 2.4-.4 3.1-1.3.7-.7 1-1.8.9-2.9zm-18-30c2.2 0 4.1 1.4 4.7 3.2h-9.5c.7-1.9 2.6-3.2 4.8-3.2zM4.5 35l2.8-23h2.2v3c0 1.1.9 2 2 2s2-.9 2-2v-3h10v3c0 1.1.9 2 2 2s2-.9 2-2v-3h2.2l2.8 23h-28z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </header>
        <nav className="navbar navbar-expand-md navbar-light d-none d-md-block">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav" style={{ display: "inline-flex" }}>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  ARMA TU BOXCHOIS
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  PRE-DISEÑADOS
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {/* <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/dia-del-padre"
                  >
                    DÍA DEL PADRE   
                  </a> */}
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-bebes"
                  >
                    PARA BEBÉS
                  </a>
                  {/* <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-bodas"
                  >
                    PARA BODAS
                  </a> */}
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-ella"
                  >
                    PARA ELLA
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/para-el"
                  >
                    PARA EL
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/ocasiones-especiales"
                  >
                    OCASIONES ESPECIALES
                  </a>
                  {/* <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/navidad-2020"
                  >
                    NAVIDAD 2020
                  </a> */}
                </div>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/collections/san-valentin"
                >
                  SAN VALENTÍN
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/collections/giftbox-con-flores"
                >
                  BOXCHOIS + FLORES
                </a>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  PRODUCTOS SUELTOS
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/tazas"
                  >
                    TAZAS
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/velas-aromaticas-de-soya"
                  >
                    VELAS
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://www.boxchois.com/collections/wine-totes"
                  >
                    WINE TOTES
                  </a>
                </div>
              </li> */}

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/pages/nosotros"
                >
                  NOSOTROS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/pages/corporativos"
                >
                  REGALOS CORPORATIVOS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.boxchois.com/pages/contacto"
                >
                  CONTACTO
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
