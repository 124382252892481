import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Continue from "./common/Continue";
import Timeline from "./common/Timeline";

class Step1 extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <div className="paso-1-section">
        <div className="container">
          <Timeline step={this.props.step} />
          <img
            src="images/Paso-1.png"
            className="img-main-mobile pasos-mobile"
            alt=""
          />
          <p className="paso-mobile-titulo mb-50">Selecciona un motivo</p>
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            keyBoardControl={true}
            deviceType={this.props.deviceType}
            containerClass="carousel-container-fluid"
          >
            <div className="text-center">
              <a
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjAzODI5OTc0Ng=="
                  )
                }
              >
                <img
                  className="selectMotivo"
                  width="266"
                  height="266"
                  src="images/imagen1.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjAzODI5OTc0Ng=="
                  )
                }
                className="text-center black mt-20 step1-title"
              >
                PARA ÉL
              </p>
            </div>
            <div className="text-center">
              <a
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTk4ODYyMzQ1OA=="
                  )
                }
              >
                <img
                  className="selectMotivo"
                  width="266"
                  height="266"
                  src="images/imagen2.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTk4ODYyMzQ1OA=="
                  )
                }
                className="text-center black mt-20 step1-title"
              >
                PARA ELLA
              </p>
            </div>
            <div className="text-center">
              <a
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTQ1MTU1NTkzOA=="
                  )
                }
              >
                <img
                  className="selectMotivo"
                  width="266"
                  height="266"
                  src="images/imagen3.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTQ1MTU1NTkzOA=="
                  )
                }
                className="text-center black mt-20 step1-title"
              >
                PARA BEBÉS
              </p>
            </div>

            <div className="text-center">
              <a
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjQ0MjkxOTAxMA=="
                  )
                }
              >
                <img
                  className="selectMotivo"
                  width="266"
                  height="266"
                  src="images/imagen4.png"
                  alt=""
                />
              </a>
              <p
                onClick={() =>
                  this.props.addCatToCart(
                    "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjQ0MjkxOTAxMA=="
                  )
                }
                className="text-center black mt-20 step1-title"
              >
                OCASIONES ESPECIALES
              </p>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default Step1;
