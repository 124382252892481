import React, { Component } from "react";

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1;
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1;
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }


  render() {
    const { id } = this.props.line_item.variant;
    return (
      <div className="">
        {id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==" &&
          id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==" &&
          id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==" &&
          id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==" &&
          id != "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==" ? (
          <div className="text-center">
            <p className="text-center black">{this.props.line_item.title}</p>

            {this.props.line_item.variant.image != null ? (
              <img
                width="240"
                src={this.props.line_item.variant.image.src}
                alt={`${this.props.line_item.title} product shot`}
                style={{ maxHeight: "165px" }}
              />
            ) : (
              <img
                width="240"
                src="https://via.placeholder.com/400"
                alt={`${this.props.line_item.title} product shot`}
                style={{ maxHeight: "165px" }}
              />
            )}
            {id !=
              "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==" &&
              id !=
              "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==" &&
              id !=
              "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==" &&
              id !=
              "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==" &&
              id !=
              "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==" ? (
              <p className="quantity1">{this.props.line_item.quantity}</p>
            ) : null}
            <div className="Line-item__content-row">
              {id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==" ? (
                <div className="Line-item__quantity-container">
                  <button
                    className="Line-item__quantity-update"
                    onClick={() =>
                      this.props.decrementQuantity2(this.props.line_item)
                    }
                  >
                    -
                  </button>
                  <span className="Line-item__quantity">
                    {this.props.line_item.quantity}
                  </span>
                  <button
                    className="Line-item__quantity-update"
                    onClick={() =>
                      this.props.incrementQuantity2(
                        this.props.line_item,
                        this.props.line_item.id
                      )
                    }
                  >
                    +
                  </button>
                </div>
              ) : null}

              <span className="Line-item__price">
                ${" "}
                {(
                  this.props.line_item.quantity *
                  this.props.line_item.variant.price.amount
                ).toFixed(2)}
              </span>
              {id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMzMzMTkzOA==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwMjk3MTQ5MA==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNTgyMjMwNg==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwNjczOTgxMA==" &&
                id !=
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTEyNDEwODgwNDE5NA==" ? (
                <button
                  className="Line-item__remove"
                  onClick={() =>
                    this.props.removeLineItemInCart(
                      this.props.line_item.id,
                      this.props.line_item.variant.id
                    )
                  }
                >
                  ×
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LineItem;
