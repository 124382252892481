import React, { Component } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "contents",
  },
  overlay: {
    overflow: "scroll",
    zIndex: 99999,
  },
};
Modal.setAppElement("#root");

class ModalTarjeta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNoteAttributes: true,
      blankNotes: false,
      personalDelivery: false,
      note: "",
      de_nota: "",
      para_nota: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
    this.handleNoteIdentity = this.handleNoteIdentity.bind(this);
    this.handleShowNoteAttributes = this.handleShowNoteAttributes.bind(this);
    this.handlePersonalDelivery = this.handlePersonalDelivery.bind(this);
    this.handleBlankNotes = this.handleBlankNotes.bind(this);

  }



  handleNoteIdentity(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(event) {
    this.setState({ note: event.target.value });
  }

  handleShowNoteAttributes() {
    this.setState(prev => ({ ...prev, showNoteAttributes: !prev.showNoteAttributes }))
  }
  handleBlankNotes() {
    this.setState(prev => ({ ...prev, blankNotes: !prev.blankNotes }))
  }
  handlePersonalDelivery() {
    this.setState(prev => ({ ...prev, personalDelivery: !prev.personalDelivery }))
  }

  addVariantToCart(variantId, quantity) {
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target;
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.product.variants.find((variant) => {
      return variant.selectedOptions.every((selectedOption) => {
        return (
          selectedOptions[selectedOption.name] ===
          selectedOption.value.valueOf()
        );
      });
    });

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image.src,
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value,
    });
  }

  renderModal = () => {
    if (this.props.modalId !== null) {
      const product = this.props.tarjetas[this.props.modalId];
      let variantImage;
      console.log(product);

      // let variantImage = "https://via.placeholder.com/400";
      if (product.images[0] != undefined) {
        variantImage = this.state.selectedVariantImage || product.images[0].src;
      }

      let variant = this.state.selectedVariant || product.variants[0];
      let variantQuantity = this.state.selectedVariantQuantity || 1;
      return (
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="image-variant">
                  {product.images.length ? (
                    <img
                      // className="card-img-top img-resp"
                      src={variantImage}
                      alt={variantImage}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 text-justify">
                <form action="">

                  <div className="tarjeta-checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      onChange={this.handlePersonalDelivery} />
                    Lo recibiré y entregaré personalmente
                  </div>

                  {
                    ((this.state.blankNotes) && (this.state.personalDelivery)) ?
                      (<><div></div></>
                      ) : this.state.blankNotes ?
                        (
                          <>
                            <div>
                              <label htmlFor="De_nota" className="d-block ">De:</label>
                              <input
                                id="De_nota"
                                type="text"
                                className="w-100 mb-3"
                                name="de_nota"
                                value={this.state.de_nota}
                                onChange={this.handleNoteIdentity}
                              />

                              <label htmlFor="Para_nota" className="d-block" >Para:</label>
                              <input
                                id="Para_nota"
                                type="text"
                                className="w-100 mb-3"
                                name="para_nota"
                                value={this.state.para_nota}
                                onChange={this.handleNoteIdentity}
                              />
                            </div>
                          </>
                        )
                        : this.state.personalDelivery ?
                          (
                            <>
                              <textarea
                                name="texto_tarjeta"
                                id="texto_tarjeta"
                                style={{ width: "100%" }}
                                rows="10"
                                value={this.state.note}
                                onChange={this.handleChange}
                                maxLength="255"
                                placeholder="Escribe el contenido de tu tarjeta aquí">
                              </textarea>
                            </>
                          )
                          :
                          (
                            <>
                              <div>
                                <label htmlFor="De_nota" className="d-block ">De:</label>
                                <input
                                  id="De_nota"
                                  type="text"
                                  className="w-100 mb-3"
                                  name="de_nota"

                                  value={this.state.de_nota}
                                  onChange={this.handleNoteIdentity}
                                />

                                <label htmlFor="Para_nota" className="d-block" >Para:</label>
                                <input
                                  id="Para_nota"
                                  type="text"
                                  className="w-100 mb-3"
                                  name="para_nota"

                                  value={this.state.para_nota}
                                  onChange={this.handleNoteIdentity}
                                />
                              </div>

                              <textarea
                                name="texto_tarjeta"
                                id="texto_tarjeta"
                                style={{ width: "100%" }}
                                rows="10"
                                value={this.state.note}
                                onChange={this.handleChange}

                                maxLength="255"
                                placeholder="Escribe el contenido de tu tarjeta aquí">
                              </textarea>
                            </>
                          )


                  }
                  <div className="tarjeta-checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      onChange={this.handleBlankNotes} />
                    Selecciona esta casilla para recibir la tarjeta en blanco
                  </div>
                </form>
              </div>
              <div className="col-md-12">
                {/* <button
                  style={{ boxShadow: "none", backgroundColor: "#fff0ce" }}
                  className="button"
                  onClick={() =>
                    this.props.addVariantToCart(
                      variant.id,
                      variantQuantity,
                      this.props.modalId,
                      this.state.note
                    )
                  }
                >
                  AGREGAR
                </button> */}
                <button
                  style={{ boxShadow: "none", backgroundColor: "#fff0ce" }}
                  className="button"
                  onClick={() => this.props.addTarToCart(
                    product.title,
                    product.variants[0].image.src,
                    product.variants[0].id,
                    this.state.note,
                    this.state.de_nota,
                    this.state.para_nota

                  )}>
                  {this.state.showNoteAttributes ? "AGREGAR" : "CONTINUAR"}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Producto Modal"
        addTarToCart={this.props.addTarToCart}
      >
        <div className="modal-wrapper">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Informacion Adicional
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div>{this.renderModal()}</div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ModalTarjeta;
