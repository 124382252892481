import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Client from "shopify-buy";
import BuildForm from "./components/BuildForm";
import "./App.css";

const client = Client.buildClient({
  storefrontAccessToken: "7b643b3739cf1be8aa677356f20550f4",
  domain: "box-chois.myshopify.com"
});

// ReactDOM.render(<App client={client} />, document.getElementById('root'));

ReactDOM.render(<BuildForm client={client} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
